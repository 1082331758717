
//
// Option
//




// Base
@mixin kt-option-base() {
    .kt-option  {
        display: flex;       
        align-items: center;
        padding: 1.4em;        
        border: 1px solid kt-base-color(grey, 2);

        @include kt-rounded {
            border-radius: $kt-border-radius;
        }
        
        .kt-option__control {
        	width: 1.7rem;
            padding-top: 0.1rem;      	

        	.kt-radio {
        		display: block;
        	}
        }

        .kt-option__label {
            width: 100%;

        	.kt-option__head {
        		display: flex;
                justify-content: space-between;  
                align-items: center;              

        		.kt-option__title {
        			font-size: 1rem;    
                    font-weight: 400;     	
                    color: kt-base-color(label, 3);
        		}

        		.kt-option__focus {
        			font-size: 1rem;
    				font-weight: 500; 		
                    color: kt-base-color(label, 4);
        		}
        	}

        	.kt-option__body {
        		display: block;
        		padding-top: 0.7rem; 
        		font-size: 0.9rem;     	
                color: kt-base-color(label, 2);
        	}
        }

        &.kt-option--plain {
            border: 0;
            padding: 0; 
            margin-top: -0.2rem;
            margin-bottom: 2rem; 
            align-items: center;      

            .kt-option__control {
                vertical-align: middle;                                               
                width: 2.7rem;

                .kt-radio {
                    margin-bottom: 1.6rem;                     
                }
            }

            .kt-option__label {
                .kt-option__head {
                    .kt-option__title {
                                                           
                    }                    
                }

                .kt-option__body {
                    padding-top: 0.2rem;                              
                }
            }
        }

        // Validation 
        .is-valid & {
            border-color: kt-state-color(success, base);
        }

        .is-invalid & {
            border-color: kt-state-color(danger, base);
        }
    }     


}

// Validation State
@mixin kt-option-validation-state($state, $color) {
    .has-#{$state} {
        .kt-option {
            .kt-option__label {
                .kt-option__head {
                    .kt-option__title {
                        color: $color;
                    }
                }
            }
        }
    }
}

// Base
@include kt-option-base();

// Validation States
@include kt-option-validation-state(success, kt-get($kt-state-colors, success, base));
@include kt-option-validation-state(error, kt-get($kt-state-colors, warning, base));
//
// Main Stylesheet
//
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
// 1: Include global config
@import "config";
// 2: Bootstrap framework includes
@import "global/integration/frameworks/bootstrap/variables";
@import "~bootstrap/scss/bootstrap";
@import "global/integration/frameworks/bootstrap/bootstrap.scss";
@import "global/integration/frameworks/react/material-ui/general";
// Functions
@import "base/functions";
// Mixins
@import "base/mixins";
@import "components/mixins";
// Global variables
@import "components/variables.demo";
@import "components/variables.bootstrap";
@import "components/variables.custom";
//components
@import "components/utilities",
"components/burger-icon",
"components/card",
"components/wizard",
"components/type",
"components/breadcrumb",
"components/nav",
"components/alert",
"components/buttons",
"components/code",
"components/dropdown",
"components/forms",
"components/grid",
"components/modal",
"components/progress",
"components/table",
"components/popover",
"components/tooltip",
"components/accordion",
"components/datatable",
"components/checkbox",
"components/radio",
"components/switch",
"components/option",
"components/dialog",
"components/strikethrough",
"components/separator",
"components/image-input",
"components/label",
"components/loader",
"components/symbol",
"components/pulse",
"components/page-loader",
"components/quick-search",
"components/scrolltop",
"components/spinner",
"components/sticky-toolbar",
"components/svg-icon",
"components/svg",
"components/timeline",
"components/timeline-2",
"components/timeline-3",
"components/timeline-4",
"components/timeline-5",
"components/timeline-6",
"components/timeline-7",
"components/overlay",
"components/wave",
"components/list",
"components/bullet",
"components/navi",
"components/ribbon",
"components/offcanvas",
"components/toggle";
// 3: Components include
@import "components/stepper";
@import "global/include.react";
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
// 4: Layout config
@import "global/layout/config";
@import "global/layout/header/config";
@import "global/layout/brand/config";
@import "global/layout/aside/config";
@import "global/layout/footer/config";
// 5: Layout includes
@import "global/layout/base/base";
@import "global/layout/header/header";
@import "global/layout/header/base-mobile";
@import "global/layout/header/menu";
@import "global/layout/header/topbar";
@import "global/layout/brand/brand";
@import "global/layout/aside/aside";
@import "global/layout/content/content";
@import "global/layout/footer/footer";
// 6:  User defined custom styles includes
@import "custom/layouts/aside";
@import "custom/layouts/header";
@import "custom/layouts/get-started";
@import "custom/components/buttons";
@import "global/integration/plugins/datatables";
@import "global/integration/plugins/apexcharts";
@import "global/components/base/input-icon";
// Page Includes
@import "pages/pricing/pricing-1.scss";
@import "pages/pricing/pricing-2.scss";
@import "pages/support-center/faq-2.scss";
@import "pages/support-center/home-2.scss";
@import "pages/support-center/faq-3.scss";
@import "pages/todo/todo.scss";

:root {
    --kt-white: #ffffff;
    --kt-dark: #181C32;
    --kt-primary: #5867dd;
    --kt-primary-light: #f1faff;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-loading-icon {
    padding-right: "3.5rem";
}

.show>.btn-success.dropdown-toggle,
.btn-success.dropdown-toggle,
.btn-success.dropdown-toggle:hover {
    border: 0;
}

.max-length-param {
    font-size: 0.8rem;
    color: #797474;
}

.kt-mycart__pic .kt-svg-icon {
    height: 5rem;
    width: 5rem;
}

.kt-mycart-svg svg {
    height: 10rem;
    width: 10rem;
    margin-bottom: 6px;
}

.kt-mycart-svg svg g [fill] {
    fill: #74788d !important;
}

.rdrDateRangePickerWrapper .rdrDateDisplayWrapper,
.rdrDateRangePickerWrapper .rdrMonthAndYearPickers {
    display: none;
}

.rdrMonthAndYearWrapper .rdrNextPrevButton {
    z-index: 99;
}

.kt-content {
    padding: 0;
}

.kt-wizard-v4__form .kt-avatar__cancel {
    display: flex;
}

.kt-wizard-v4__form .kt-avatar .kt-avatar__holder {
    width: 100px;
    height: 100px;
}

.kt-mycart__pic svg g [fill] {
    fill: #0abb87 !important;
}

.img-cropper .btn-group {
    margin-right: 5px;
}

.nav-slider .slick-active .carousel {
    cursor: auto;
    color: #fff;
    background-color: #5d78ff;
    -webkit-box-shadow: 0px 2px 14px 2px rgba(93, 120, 255, 0.2);
    box-shadow: 0px 2px 14px 2px rgba(93, 120, 255, 0.2);
}

.nav-slider .slick-active .carousel span {
    color: #fff !important;
}

.kt-widget30 .kt-widget30__head .owl-carousel .carousel {
    outline: none;
}

.kt-social__icon {
    padding: 5px;
    cursor: pointer;
    font-size: 1.4rem;
}

.kt-icon__active {
    color: #5867dd;
    background-color: #f7f8fa;
}

.kt-media__img {
    max-width: 100px;
    max-height: 100px;
}

.btn-icon-custom i {
    color: #fff !important;
}

.kt-expansion__panel {
    box-shadow: none !important;
}

.kt-dashboard__section-3 {
    position: relative;
    height: 175px;
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    // background-image: url(/media/logos/adc-icon-white.png);
    background-position: calc(100% - 2rem) calc(100% - 2rem);
    background-size: 15% auto;
    img {
        position: absolute;
        width:15%;
        right: 1rem;
        bottom: 2rem;
    }
}

// .kt-dashboard__section-4 {
//     background-color: #1B283F;
//     background-position: 0 calc(100% + 0.5rem);
//     background-size: 100% auto;
//     background-image: url(/media/svg/patterns/rhone.svg);
// }

.kt-dashboard__section-last {
    background-position: calc(100% + 0.5rem) bottom;
    background-size: auto 70%;
    background-image: url(/media/svg/humans/custom-3.svg);
}

.rounded-xl {
    border-radius: 1.25rem !important;
}

.bgi-no-repeat {
    background-repeat: no-repeat;
}

.p-8 {
    padding: 2rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 1.5rem !important;
}

.pt-6,
.py-6 {
    padding-top: 1.5rem !important;
}

.pt-9,
.py-9 {
    padding-top: 2rem !important;
}

.custom-pt-9 {
    padding-top: 25px !important;
    padding-bottom: 0px !important;
}

.p-5 {
    padding: 1.25rem !important;
}

.pl-8,
.px-8 {
    padding-left: 2rem !important;
}

.pr-8,
.px-8 {
    padding-right: 2rem !important;
}

.mb-6,
.my-6 {
    margin-bottom: 1.5rem !important;
}

.mt-6,
.my-6 {
    margin-top: 1.5rem !important;
}

.mb-9,
.my-9 {
    margin-bottom: 2.25rem !important;
}

.mb-12,
.my-12 {
    margin-bottom: 3rem !important;
}

.mr-8,
.mx-8 {
    margin-right: 2rem !important;
}

.ml-8,
.my-8 {
    margin-left: 2rem !important;
}

.ml-4,
.my-4 {
    margin-left: 1rem !important;
}

.ml-16,
.my-16 {
    margin-left: 4rem !important;
}

.mr-16,
.mx-16 {
    margin-right: 4rem !important;
}

.py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.max-h-100 {
    max-height: 100px !important;
    width: auto !important;
}

.font-size-h4 {
    font-size: 1.35rem !important;
}

.text-dark {
    color: #181C32 !important;
}

.text-gray {
    color: gray;
}

.text-gray-800 {
    color: #3f4254 !important;
}

.font-size-lg {
    font-size: 1.08rem !important;
}

.text-dark-50 {
    color: #7E8299 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.spinner-custom {
    width: 20px !important;
    height: 20px !important;
    margin-left: 0px !important;
    margin-right: 5px;
}

.img-overlay {
    background: #00000036;
    color: #fff;
}

.img-overlay i {
    color: #fff !important;
}

.height-100 {
    height: 100px !important;
}

.width-100 {
    width: 100% !important;
}

.kt-expansion__media .MuiButtonBase-root {
    display: none !important
}

.kt-checkbox {
    padding-left: 0px !important;
    margin-bottom: 0px !important;
}

.dropdown-item .kt-checkbox {
    padding-left: 30px !important;
    margin-bottom: 10px !important;
}

.react-bootstrap-table.custom-table .table.table-head-custom thead tr .sortable-active {
    color: #5867dd !important;
    font-weight: bold !important;
}

.custom-table i.header {
    color: #5867dd !important;
    font-size: 0.6rem !important;
    display: inline-block;
    position: relative;
    right: -10px;
    line-height: 0;
    vertical-align: middle;
}

.kt-login__form {
    max-width: 450px !important;
}

.font-size-md {
    font-size: 13px !important;
}

.font-size-custom {
    font-size: 16px !important;
}

.text-dark-custom{
    color: #7e8299 !important;
}

a.text-dark-custom:hover {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// .logo-dashboard {
//     padding: 2rem 2.25rem !important;
// }

.v-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1024px) {
    .rdrDateRangePickerWrapper .rdrMonthName {
        text-align: center;
        position: relative;
        top: -40px;
    }
}

@media (min-width: 320px) {
    // .logo-dashboard {
    //     padding: 2.3rem 2rem !important;
    // }
}

@media (min-width: 425px) {
    // .logo-dashboard {
    //     padding: 1rem 2.25rem !important;
    // }
}

@media (min-width: 768px) {
    // .logo-dashboard {
    //     padding: 1rem 16.25rem !important;
    // }
}

@media (min-width: 1024px) {
    // .logo-dashboard {
    //     padding: 1rem 26.25rem !important;
    // }
}

@media (max-width: 1200px) {
    .card-large{
        width: 100% !important;
        padding: 0 10px !important;
    }
    .adscard{
        padding: 0 10px !important;
    }
}

@media (min-width: 1200px) {
    // .logo-dashboard {
    //     padding: 2.5rem 0.45rem !important;
    // }
}

@media (min-width: 1360px) {
    // .logo-dashboard {
    //     padding: 2.3rem 0.25rem !important;
    // }
}

@media (min-width: 1440px) {
    // .logo-dashboard {
    //     padding: 2.5rem 2.25rem !important;
    // }
}

@media (min-width: 1500px) {
    // .logo-dashboard {
    //     padding: 2rem 2.25rem !important;
    // }
}

@media (min-width: 1920px) {
    // .logo-dashboard {
    //     padding: 2rem 2.25rem !important;
    // }
}

@media (min-width: 2500px) {
    // .logo-dashboard {
    //     padding: 1rem 5.25rem !important;
    // }
}

.table {
    color: #3f4254 !important;
    font-weight: 400 !important;
}

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
    color: #b5b5c3 !important;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: .925rem !important;
    line-height: 1.35 !important;
}

.react-bootstrap-table-pagination-total {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #3f4254 !important;
}

.custom-portlet__padding {
    padding: 2rem 2.25rem !important;
}

.kt-portlet__margin {
    margin-bottom: 50px !important;
}

.kt-highlight {
    background-color: yellow;
    padding: 3px;
    color: #000000;
}

.overlay.overlay-block {
    cursor: auto !important;
}

.kt-svg-success {
    fill: #0abb87 !important;
}

.kt-svg-info {
    fill: #5578eb !important;
}

.kt-svg-danger {
    fill: #fd397a !important;
}

.kt-svg-warning {
    fill: #ffb822 !important;
}

.custom-header-product .MuiCardHeader-action {
    margin-top: 0px !important;
    margin-right: 0px !important;
}

.adheading {
    color: #4078f8
}

.adurl {
    color: #34a853
}

.custom-range-slider .ion-range-slider {
    text-align: center;
}

.custom-label {
    background-color: #ed5565;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 5px;
    color: #fff;
    position: relative;
    top: -3px;
}

.custom-p-badge {
    padding: 3px 5px !important;
    border-radius: 5px;
}

.text-underline {
    text-decoration: underline;
    text-underline-position: under;
}

.kt-form-profile .form-group label {
    color: #646c9a !important;
}

.custom-add-icon {
    color: #0abb87;
    padding-top: 42px;
    font-size: 16px;
}

.delete-added {
    width: 94%;
    margin-right: 10px;
    display: inline-block;
}

@media (max-width: 1280px) {
    .kt-callout .kt-callout__body .kt-callout__content .kt-callout__title {
        font-size: 1.45rem;
    }

    .kt-callout .kt-callout__body .kt-callout__content .kt-callout__desc {
        font-size: 1.10rem;
        padding-right: 1rem;
    }
}

@media (max-width: 1360px) {
    .delete-added {
        width: 93%;
    }
}

svg.apexcharts-svg {
    overflow: visible !important;
}

.dual-listbox__button {
    border: none;
    border-radius: .42rem;
    padding: .55rem .75rem;
    color: #7e8299;
    font-weight: 500;
    font-size: .9rem;
    background-color: #f3f6f9;
    width: 70%;
    margin: 0px auto 0.5rem !important;
}

.dual-listbox__button i {
    font-size: .6rem !important;
    color: #b5b5c3 !important;
}

.list-group-item {
    border: none !important;
    border-bottom: 1px solid #ebedf2 !important;
}

.list-group-item:active,
.list-group-item:hover {
    color: #495057 !important;
    background-color: #fff !important;
}

.list-group-item.active {
    color: #212529 !important;
    background-color: #e9ecef !important;
}

.overflow-shown {
    overflow: visible;
    z-index: 10;
}

.zindex-low {
    z-index: 9;
}

.temp-3.kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
    flex-basis: calc(33.333% - 0.25rem);
}

.kt-wizard-v4.kt-wizard-custom-tabs .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
    flex-basis: calc(20% - 0.25rem);
    width: calc(20% - 0.25rem);
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    margin-bottom: 0.5rem;
    flex: 1;
    margin-right: 0.5rem;
}

.kt_stats_widget_7_chart .apexcharts-canvas,
.kt_stats_widget_12_chart .apexcharts-canvas {
    height: 150px !important;
    display: flex;
    align-items: flex-end;
}

.billing-table thead th {
    color: #b5b5c3 !important;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

.billing-table tbody td,
.billing-table tbody th {
    padding: 0.75rem;
    color: #3f4254 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    text-transform: capitalize;
}

@media (max-width: 768px) {
    .kt-wizard-v4.kt-wizard-custom-tabs .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item {
        width: 100%;
    }

    .delete-added {
        width: 89%;
    }

    .makeStyles-customWidth-19 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media (min-width: 1025px) {
    .kt-aside--minimize .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
        display: flex !important;
        margin-left: 10px;
    }

    .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
        margin-left: 10px;
    }
}

.toast.notif-success {
    background-color: #0abb87 !important;
    color: #fff !important;

    .toast-header {
        background-color: #0abb87 !important;
        color: #fff !important;
    }
}

.toast.notif-error {
    background-color: #ce8887 !important;
    color: #fff !important;

    .toast-header {
        background-color: #ce8887 !important;
        color: #fff !important;
    }

    z-index: 1500 !important;
}

.preview-img {
    margin-top: 10px;
    max-width: 150px;
}

.right-align {
    display: flex;
    justify-content: flex-end;
}

.login-as-bar {
    position: sticky;
    z-index: 99;
    bottom: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.get-started-view a.kt-menu__link,
.get-started-view .kt-header__topbar,
.get-started-view .kt-subheader__toolbar {
    pointer-events: none;
}

.get-started-view .kt-header__topbar-item.kt-header__topbar-item--langs {
    pointer-events: all;
}

.kt-login.kt-login--v1 .kt-login__aside .kt-login__logo {
    display: flex;
}

.display-flex {
    display: flex;
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
}

.px-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important
}

.px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important
}

.px-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important
}

.px-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
}

.px-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important
}

.px-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important
}

.px-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.px-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important
}

.px-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important
}

.px-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important
}

.px-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important
}

.px-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important
}

.px-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
}

.px-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important
}

.px-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important
}

.svg-icon {
    line-height: 1;
}


.svg-custom-height {
    height: 1.5rem !important;
    width: 3.25rem !important;
}

.svg-custom-google-size {
    height: 2rem !important;
    width: 2rem !important;
}

// .svg-tiktok-size {
//     height: 1.5rem !important;
//     width: 1.5rem !important;
// }

.ms-5 {
    margin-left: 1.25rem !important;
}

.translate-middle-y {
    transform: translateY(-50%) !important;
}

.top-50 {
    top: 50% !important;
}

.h-440 {
    height: 440px !important;
}

.flex-stack {
    justify-content: space-between;
    align-items: center;
}

.d-flex {
    display: flex !important;
}

.text-gray-900 {
    color: #181c32 !important;
}

.text-hover-primary {
    transition: color .2s ease, background-color .2s ease;
}

.fw-bolder {
    font-weight: 600 !important;
}

.fs-5 {
    font-size: 1.15rem !important;
}

.fs-4 {
    font-size: 1.08rem !important;
}

.text-gray-400 {
    color: #b5b5c3 !important;
}

.text-muted {
    color: #a1a5b7 !important;
}

.fs-6 {
    font-size: 0.8rem !important;
}

.fs-7 {
    font-size: .925rem !important;
}

.fs-8 {
    font-size: 1.175rem !important;
}

.pt-7 {
    padding-top: 1.75rem !important;
}

.card.card-flush>.card-header {
    border-bottom: 0;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
}

.container-chat {
    .label {
        &.label-sm {
            &.label-success {
                background-color: #1BC5BD;
            }
        }
    }

    .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + 1.3rem + 2px) !important;
        padding: 0.65rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e2e5ec;
        border-radius: 4px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 90;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #3F4254;
        text-align: left;
        list-style: none;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 0 solid rgba(0, 0, 0, 0.15);
        border-radius: 0.42rem;
        // -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105 , 15%);
        // box-shadow: 0px 0px 50px 0px rgba(82, 63, 105 , 15%);

        &.show {
            display: block;
        }
    }

    .dropdown-menu:before,
    .dropdown-menu:after {
        display: none !important;
    }

    .dropdown-menu[x-placement^=top],
    .dropdown-menu[x-placement^=right],
    .dropdown-menu[x-placement^=bottom],
    .dropdown-menu[x-placement^=left] {
        right: auto;
        bottom: auto;
    }

    .dropdown-menu {
        left: 0
            /*rtl:ignore*/
        ;

        .navi {
            .navi-item {
                .navi-link {
                    background-color: transparent;
                    border: none;
                    width: 100%;
                    text-align: left;
                    color: #343a40;
                    font-weight: 300;
                }
            }
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .dropdown-menu-left {
        right: auto;
        left: 0;
    }

    .modelStyle {
        display: block;
        padding-right: 17px
    }

    .modalHidden {
        display: none;

    }


    .fade:not(.show) {
        opacity: 0;
    }
}

.google-integrations {
    &.row {
        .col-lg-6 {
            padding-bottom: 20px;

            .kt-portlet {
                &.kt-callout {
                    margin-bottom: 0;
                    height: 100%;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .flex-lg-row-fluid {
        flex: 1 auto;
        min-width: 0;
    }
}

@media (min-width: 1200px) {
    .ms-xl-10 {
        margin-left: 2.5rem !important;
    }
}

@media (min-width: 992px) {
    .ms-lg-7 {
        margin-left: 1.75rem !important;
    }
}

@media (min-width: 1000px) {

    .container-xl-chat,
    .container-lg-chat,
    .container-md-chat,
    .container-sm-chat,
    .container-chat {
        max-width: 1065px;
    }

    .w-xl-400px {
        width: 400px !important;
    }
}

@media (min-width: 1200px) {

    .container-xl-chat,
    .container-lg-chat,
    .container-md-chat,
    .container-sm-chat,
    .container-chat {
        max-width: 100%;
        padding: 0px;
    }

    .w-xl-400px {
        width: 400px !important;
    }
}


@media (min-width: 1400px) {

    .container-chat,
    .container-lg-chat,
    .container-md-chat,
    .container-sm-chat,
    .container-xl-chat,
    .container-xxl-chat {
        max-width: 1320px;
    }
}

.ms-1 {
    margin-left: .25rem !important;
}

.ms-3 {
    margin-left: .75rem !important;
}

.me-1 {
    margin-right: .25rem !important;
}

.me-3 {
    margin-right: .75rem !important;
}

.symbol.symbol-35px>img {
    width: 35px;
    height: 35px;
}

.text-dark-50 {
    color: #7E8299 !important;
}

.max-w-400px {
    max-width: 400px !important;
}

.bg-light-primary {
    background-color: #E1F0FF !important;
}

.fw-bold {
    font-weight: 500 !important;
}

.bg-light-success {
    background-color: #C9F7F5 !important;
}

.bg-light-primary {
    background-color: #E1F0FF !important;
}

.scroll {
    position: relative;
    overflow: hidden;
}

.scroll.scroll-pull {
    padding-right: 12px;
    margin-right: -12px;
}

.display-end {
    float: right;
}

.display-start {
    float: left;
}

button.kt-menu__toggle.active {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
}

button.kt-menu__toggle.active .kt-menu__link-text {
    color: #fff !important;
}

.nav-5-items .kt-wizard-v4__nav-item {
    flex-basis: calc(20% - 0.5rem) !important;
    width: calc(20% - 0.5rem) !important;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions .btn.hide {
    display: none !important;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form__actions .btn.show {
    display: block !important;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .form-group {
    position: relative;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .form-group .label-abs {
    position: absolute;
    top: 35px;
    right: 20px;
}


.callsClass {
    flex-direction: column;
    // align-self:  flex-end;
    justify-items: end;
    justify-content: end;
}

.payout-info-btn {
    color: #fff;
    background-color: #3758ff;
    border-color: #2a4eff;
}

.payout-info-btn:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.refer-btn {
    color: #fff;
    background-color: #3758ff;
    border-color: #2a4eff;
    // box-shadow: 0 0 0 0.2rem rgb(117 140 255 / 50%);
}

.refer-btn:hover {
    color: #fff;
    background-color: #3758ff;
    border-color: #2a4eff;
}

.support-btn {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    border: 0;
    color: #fff;
    font-weight: 500;
}

.support-btn:hover {
    background-color: #5867dd;
    color: #fff;
    font-weight: 500;
    border: 0;
}

@keyframes glowing {
    0% {
        background-color: white;
        box-shadow: 0 0 0px red;
    }

    // 50% {
    //     background-color: white;
    //     box-shadow: 0 0 0px red;
    // }

    100% {
        background-color: red;
        box-shadow: 0 0 0px red;
    }
}

.blinkButton {
    animation: glowing 1300ms infinite;
}

.blinkButtonStop {
    display: none;
}

.planText {
    min-height: 26px;
    display: inline-flex;
    align-items: center;
}

.margintext {
    margin-left: 6mm;
}

.planHeadingText {
    color: #707070 !important;
}

.search-icon {
    transform: translateY(-50%);
    left: 15px;
    stroke: #5867dd;
}

.input-search {
    padding-left: 40px;
}

// .card .card-body a {
//     color: #181c32 !important;
//     font-size: 1.075rem !important;
// }

.ticket-mb-10 {
    margin-bottom: 2.5rem !important;
}

.badge-light {
    color: #7e8299;
    background-color: #f5f8fa;
}

.ticket-box {
    width: 100% !important;
}

.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #50cd89;
}

.svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #ffc700;
}

.datePicker {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
}

.drag-drop-container {
    padding: 0px 1%;
}

.box {
    position: relative;
    background: #ffffff;
    width: 100%;
}

.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 10px;
}

.box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
}

.dropzone-wrapper {
    border: 2px dashed #91b0b3;
    color: #92b0b3;
    // position: relative;
    height: 150px;
}

.dropzone-desc {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 40%;
    top: 50px;
    font-size: 16px;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 150px;
    cursor: pointer;
    opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
    background: #ecf0f5;
}

.dropzone {
    &.dropzone-media {
        position: relative;
        opacity: 1;
    }
}

.preview-zone {
    text-align: center;
}

.preview-zone .box {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
}

.l-color {
    color: #43494e;
}

.fix-height {
    max-height: 30%;
}

.mt-n20 {
    margin-top: -0.5rem !important;
}

.me-7 {
    margin-right: 1.75rem !important;
}

.float-end {
    float: right !important;
}


.ticket-font {
    font-size: 20px !important;
    cursor: pointer;
}


.ticket-label {
    font-size: 15px !important;
}

.mb-13 {
    margin-bottom: 3.25rem !important;
}

.fw-bold {
    font-weight: 500 !important;
}

.fs-5 {
    font-size: 1.15rem !important;
}

.mb-10 {
    margin-bottom: 2.5rem !important;
}

// .dragAndDrop-title {
//     font-size: 2rem;
//     text-align: center !important;
//     margin-top: 10%;
//     color: #5867dd;
//     font-weight: bold;
// }

// .dragAndDrop-content {
//     background-color: white;
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// }

// // .dragAndDrop-container {

// // }

// .dragAndDrop-container p {
//     color: red;
//     text-align: center;
// }

// .dragAndDrop-drop-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin: 0;
//     // width: 740px;
//     height: 200px;
//     border: 1px dashed #5867dd;
// }

// .dragAndDrop-drop-upload-icon {
//     width: 50px;
//     height: 50px;
//     background: url(../media/plugins/jstree/upload.png) no-repeat center center;
//     background-size: 100%;
//     text-align: center;
//     margin: 0 auto;
//     padding-top: 30px;
// }

// .dragAndDrop-drop-drop-message {
//     text-align: center;
//     color: #5867dd;
//     font-family: Arial;
//     font-size: 20px;
// }


// .dragAndDrop-drop-file-display-container {
//     position: fixed;
//     width: 805px;
// }

// .dragAndDrop-drop-file-status-bar {
//     width: 100%;
//     vertical-align: top;
//     margin-top: 10px;
//     margin-bottom: 20px;
//     position: relative;
//     line-height: 50px;
//     height: 50px;
// }

// .dragAndDrop-drop-file-status-bar>div {
//     overflow: hidden;
// }

// .dragAndDrop-drop-file-type {
//     display: inline-block !important;
//     position: absolute;
//     font-size: 12px;
//     font-weight: 700;
//     line-height: 13px;
//     margin-top: 25px;
//     padding: 0 4px;
//     border-radius: 2px;
//     box-shadow: 1px 1px 2px #abc;
//     color: #fff;
//     background: #5867dd;
//     text-transform: uppercase;
// }

// .dragAndDrop-drop-file-name {
//     display: inline-block;
//     vertical-align: top;
//     margin-left: 50px;
//     color: #5867dd;
// }

// .dragAndDrop-drop-file-error {
//     display: inline-block;
//     vertical-align: top;
//     margin-left: 50px;
//     color: #9aa9bb;
// }

// .dragAndDrop-drop-file-error-message {
//     color: red;
// }

// .dragAndDrop-drop-file-type-logo {
//     width: 50px;
//     height: 50px;
//     background: url(../media/plugins/jstree/generic.png) no-repeat center center;
//     background-size: 100%;
//     position: absolute;
// }

// .dragAndDrop-drop-file-size {
//     display: inline-block;
//     vertical-align: top;
//     color: #30693D;
//     margin-left: 10px;
//     margin-right: 5px;
//     margin-left: 10px;
//     color: #444242;
//     font-weight: 700;
//     font-size: 14px;
// }

// .dragAndDrop-drop-file-remove {
//     position: absolute;
//     top: 20px;
//     right: 10px;
//     line-height: 15px;
//     cursor: pointer;
//     color: red;
//     margin-right: -10px;
// }


// .image-modal {
//     z-index: 999 !important;
//     display: none;
//     overflow: hidden;
// }

// .image-modal .image-overlay {
//     width: 100%;
//     height: 100vh;
//     background: rgba(0, 0, 0, .66);
//     position: absolute;
//     top: 0;
//     left: 0;
// }

// .image-modal .image-modal-image {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     overflow: hidden;
//     object-fit: cover;
//     width: 100%;
//     height: 300px;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
// }

// .image-close {
//     position: absolute;
//     top: 15px;
//     right: 35px;
//     color: #f1f1f1;
//     font-size: 40px;
//     font-weight: bold;
//     transition: 0.3s;
// }

// .image-file-upload-btn {
//     color: white;
//     text-transform: uppercase;
//     outline: none;
//     background-color: #4aa1f3;
//     font-weight: bold;
//     padding: 8px 15px;
//     margin-bottom: 5px;
// }

.image-file-input {
    display: none;
}


// .upload-modal {
//     z-index: 999;
//     display: none;
//     overflow: hidden;
// }

// .upload-modal .overlay {
//     width: 100%;
//     height: 100vh;
//     background: rgba(0, 0, 0, .66);
//     position: absolute;
//     top: 0;
//     left: 0;
// }

// .progress-container {
//     background: white;
//     width: 500px;
//     height: 300px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     overflow: hidden;
// }

// .progress-container span {
//     display: flex;
//     justify-content: center;
//     padding-top: 20px;
//     font-size: 20px;
// }

// .progress {
//     width: 90%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #efefef;
//     height: 20px;
//     border-radius: 5px;
// }

// .progress-bar {
//     position: absolute;
//     background-color: #4aa1f3;
//     height: 20px;
//     border-radius: 5px;
//     text-align: center;
//     color: white;
//     font-weight: bold;
// }

// .error {
//     color: red;
// }

// .file-upload-btn-width {
//     width: fit-content;
// }

.red-heart-svg {
    // transition: fill 0.3s ease;
    width: 20px;
    height: 20px;
    fill: #ff0000;
}

.red-heart-svg svg g [fill] {
    // transition: fill 0.3s ease;
    width: 20px;
    height: 20px;
    fill: #ff0000 !important;
}


//drop zone metronic
.mb-8 {
    margin-bottom: 2rem !important;
}

.fw-bold {
    font-weight: 500 !important;
}

.fs-6 {
    font-size: 1.075rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.dropzone-container {
    min-height: auto;
    padding: 1.5rem 1.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px dashed #3758ff;
    background-color: #3758ff1c;
    border-radius: 0.475rem !important;
    width: 100.7%;
}


.dropzone-container .dz-preview.dz-image-preview {
    background: #fff;
}

.dropzone-container .dz-message {
    margin: 0;
    display: flex;
    text-align: left;
}

.svg-icon.svg-icon-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
}

.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7;
}

.chatHistoryHover:hover {

    .svg-icon.svg-icon-primary svg g [fill],
    .svg-icon.svg-icon-danger svg g [fill] {
        fill: #FFFFFF !important;
    }
}
.chatHistoryHover.btn.btn-label-brand {
    cursor: pointer !important;
}

// .dropzone-container .dz-preview {
//     border-radius: 0.475rem!important;
//     margin: 0.75rem;
// }
// .dropzone-container .dz-preview {
//     position: relative;
//     display: inline-block;
//     vertical-align: top;
//     margin: 16px;
//     min-height: 100px;
// }

// .dropzone-container.dz-started .dz-message {
//     display: none;
// }

// .dropzone-container.dz-clickable .dz-message, .dropzone-container.dz-clickable .dz-message * {
//     cursor: pointer;
// }
// .dropzone-container .dz-message {
//     margin: 0;
//     display: flex;
//     text-align: left;
// }
// .dropzone-container .dz-message {
//     text-align: center;
//     margin: 2em 0;
// }
// .dropzone-container.dz-clickable * {
//     cursor: default;
// }



.dropzone-container .dz-preview {
    border-radius: 0.475rem !important;
    margin: 0.75rem;
}

.dropzone-container .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone-container .dz-preview .dz-image {
    border-radius: 0.475rem !important;
    z-index: 1;
}

.dropzone-container .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10 !important;
}

.dropzone-container .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -ms-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.dropzone-container .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, .9);
    line-height: 150%;
}

.dropzone-container .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone-container .dz-preview .dz-details .dz-filename span,
.dropzone-container .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, .4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.dropzone-container .dz-preview .dz-details .dz-filename {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone-container .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone-container .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity .4s ease-in;
    -moz-transition: opacity .4s ease-in;
    -ms-transition: opacity .4s ease-in;
    -o-transition: opacity .4s ease-in;
    transition: opacity .4s ease-in;
}

.dropzone-container .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.dropzone-container .dz-preview .spinner-border {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    // height: 16px;
    left: 50%;
    top: 45%;
    margin-top: -8px;
    // width: 10px;
    margin-left: -15px;
    // background: rgba(255,255,255,.9);
    // -webkit-transform: scale(1);
    // border-radius: 8px;
    overflow: hidden;
}

.dropzone-container .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -ms-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
}


.dropzone-container .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone-container .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    // -moz-transition: opacity .3s ease;
    // -ms-transition: opacity .3s ease;
    // -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: #fff;
}


.dropzone-container .dz-error-message {
    color: #fff;
    background: #f1416c;
}

.dropzone-container .dz-preview .dz-error-mark,
.dropzone-container .dz-preview .dz-success-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 90;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone-container .dz-error-mark,
.dropzone-container .dz-success-mark {
    margin-left: -20px !important;
    margin-top: -20px !important;
}

.dropzone-container .dz-preview .dz-error-mark svg,
.dropzone-container .dz-preview .dz-success-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone-container .dz-error-mark svg,
.dropzone-container .dz-success-mark svg {
    height: 40px !important;
    width: 40px !important;
}

.dropzone-container .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -moz-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -ms-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    -o-animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
    animation: slide-in 3s cubic-bezier(.77, 0, .175, 1);
}

.dropzone-container .dz-preview .dz-error-mark,
.dropzone-container .dz-preview .dz-success-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 90;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone-container .dz-error-mark,
.dropzone-container .dz-success-mark {
    margin-left: -20px !important;
    margin-top: -20px !important;
}

.dropzone-container .dz-preview .dz-error-mark svg,
.dropzone-container .dz-preview .dz-success-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone-container .dz-error-mark svg,
.dropzone-container .dz-success-mark svg {
    height: 40px !important;
    width: 40px !important;
}

.dropzone-container .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
    z-index: 10;
}

.dropzone-container .dz-remove {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 1.65rem;
    width: 1.65rem;
    font-size: 1rem !important;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
    background-size: 40%;
    background-color: #fff !important;
    // box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
    border-radius: 100%;
    top: -0.825rem;
    right: -0.825rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A1A5B7'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.dropzone-container .dz-preview .dz-details .dz-filename span {
    border: 1px solid transparent;
}

.dropzone-container .dz-edit {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -8px;
    bottom: -8px;
    height: 1.65rem;
    width: 1.65rem;
    border-radius: 100%;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(#000000, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;

    i {
        color: kt-brand-color();
        font-size: 0.9rem;
    }

    @include kt-transition();

    &:hover {
        @include kt-transition();
        background-color: kt-brand-color();

        i {
            color: kt-brand-color(inverse);
        }
    }
}

.img-blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.align-items-center {
    align-items: center !important;
}

.ms-4 {
    margin-left: 1rem !important;
}




.text-gray-900 {
    color: #181c32 !important;
}

.fw-bolder {
    font-weight: 600 !important;
}

.fw-bolder button {
    font-weight: 600 !important;
}

.fs-5 {
    font-size: 1.15rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.coupon-input {
    // width: 210px;
    border-color: #d1d1d1;
    height: 30px;
}

.coupon-input-invalid {
    border-color: #fd397a !important;
}

.coupon-input-valid {
    border-color: #0abb87 !important;
}

.coupon-Error-text {
    position: absolute;
    left: 22px;
    font-size: 10px;
    color: #fd397a;
    font-weight: normal;
}

.me-5 {
    margin-right: 1.25rem !important;
}

.symbol.symbol-50px>img {
    width: 50px;
    height: 30px !important;
}

.symbol>img {
    width: 100%;
    max-width: 50px;
    height: 30px;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 110px;
    height: 42px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.react-date-picker-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 110px;
    height: 42px;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    margin-left: 5px;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 38px;
    height: 38px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

.toggle-text {
    color: white;
    margin: auto;
    font-size: 16px;
}

.set-height {
    height: auto !important;
}

.custom-tab-text {
    // margin: 0;
    // padding: 0 1rem 0 0;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #434349 !important;
    // display: flex;
    // align-items: center;
}

// @media (max-width: 2000px) {
body.kt-aside--minimize .gap-subheader,
body.kt-aside--minimize-hover .gap-subheader {
    padding-top: 15px !important;
}

body .gap-subheader {
    padding-top: 15px !important;
}

// }

.bubble {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    // width: 300px;
    background: #fff;
    border-radius: 40px;
    padding: 24px;
    text-align: center;
    color: #000;
}


.bubble-bottom-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 24px solid #fff;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
    border-bottom: 20px solid transparent;
    left: 32px;
    bottom: -24px;
}

.flex-row-fluid {
    flex: 1 auto;
    min-width: 0;
}

.h-40px {
    height: 40px !important;
}

.w-40px {
    width: 40px !important;
}

.py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important
}

.px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important
}

.mw-900px {
    max-width: 900px !important;
}

.flex-xl-row {
    flex-direction: row !important;
}

.py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important
}

.px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important
}

.flex-column {
    flex-direction: column !important
}

.required:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: #f1416c;
    padding-left: .25rem;
    font-weight: 700
}


.wrapper {
    background-color: white;
    width: 400px;
    padding: 2rem;
    box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
}

// .chipInput {
//     width: 100%;
//     height: 45px;
//     padding: 0 1rem;
//     margin-top: 1rem;
//     box-sizing: border-box;
//     font: inherit;
//     border-radius: 0.2rem;
//     border: 2px solid #d4d5d6;
//     color: #565656;
//     -webkit-appearance: none;
// }

// .chipInput:focus {
//     border-color: cornflowerblue;
//     outline: none;
// }

// .chipInput.has-error {
//     border-color: tomato;
// }

.error {
    margin: 0;
    font-size: 90%;
    color: tomato;
}

.tag-item {
    background-color: #5d78ff;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


:root {
    --tooltip-text-color: #a4a4a7;
    --tooltip-background-color: #f8f9fa;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
}


.Tooltip-Wrapper {
    display: inline-block !important;
    position: relative !important;
}

/* Absolute positioning */
.Tooltip-Tip {
    position: absolute;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 12px;
    // font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
}

.btn-bottom {
    position: absolute;
    /* margin-left: -50px; */
    left: 80%;
    /* width: 100px; */
    bottom: 15px;
}

.filter-shadow {
    filter: drop-shadow(2px 4px 6px grey);
}

.scroll-chips {
    max-height: 200px;
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-chips::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-chips {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.blink {
    animation: blink 3s steps(5, start) infinite;
    -webkit-animation: blink 2s steps(5, start) infinite;
    animation-delay: 2s;
}

.onboard-step-title {
    color: #3f4254;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.3rem;
}

@keyframes blink {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
}

.carousel-control-custom-prev-icon {
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    background: 50% / 100% 100% no-repeat !important;
    border-radius: 42px !important;
    background-color: grey !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
    background-size: 20px 20px !important;
}

.carousel-control-custom-next-icon {
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    background: 50% / 100% 100% no-repeat !important;
    border-radius: 42px !important;
    background-color: grey !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
    background-size: 20px 20px !important;
}

.my-modal {
    width: 74vw;
    /* Occupy the 90% of the screen width */
    max-width: 74vw;
}

.w-100 {
    width: 100% !important;
}

.pb-10 {
    padding-bottom: 2.5rem !important;
}

.pt-15 {
    padding-top: 3.75rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.mw-600px {
    max-width: 600px !important;
}

.m-5 {
    margin: 1.25rem !important;
}

.w-60 {
    width: 60%;
}

.g-9,
.gy-9 {
    --bs-gutter-y: 2.25rem;
}

.g-9,
.gx-9 {
    --bs-gutter-x: 2.25rem;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

// .btn-check:active+.btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn-check:checked+.btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn.btn-outline.btn-outline-dashed.btn-outline-default.active, .btn.btn-outline.btn-outline-dashed.btn-outline-default.show, .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active), .show>.btn.btn-outline.btn-outline-dashed.btn-outline-default {
//     color: #009ef7;
//     border-color: #009ef7;
//     background-color: #f1faff!important;
// }
// .btn-check:active+.btn.btn-outline.btn-outline-dashed, .btn-check:checked+.btn.btn-outline.btn-outline-dashed, .btn.btn-outline.btn-outline-dashed.active, .btn.btn-outline.btn-outline-dashed.show, .btn.btn-outline.btn-outline-dashed:active:not(.btn-active), .btn.btn-outline.btn-outline-dashed:focus:not(.btn-active), .btn.btn-outline.btn-outline-dashed:hover:not(.btn-active), .show>.btn.btn-outline.btn-outline-dashed {
//     border-color: #009ef7;
// }

// .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
//     box-shadow: none!important;
// }

// .btn.btn-outline.btn-outline-dashed.btn-outline-default {
//     border-width: 1px;
//     border-style: dashed;
//     color: #7e8299;
//     border-color: #e4e6ef;
// }

// .btn.btn-outline.btn-outline-dashed {
//     border-width: 1px;
//     border-style: dashed;
// }

// .btn.btn-outline-default {
//     border: 1px solid #e4e6ef;
// }

// .btn.btn-outline-dashed {
//     border: 1px dashed #e4e6ef;
// }

.col {
    flex: 1 0 0%;
}

.form-check.form-check-custom.form-check-sm .form-check-input {
    height: 1.5rem;
    width: 1.5rem;
}

// .form-check.form-check-solid .form-check-input:checked {
//     background-color: #009ef7;
// }
// .form-check.form-check-solid .form-check-input {
//     border: 0;
//     background-color: #eff2f5;
// }
// .form-check.form-check-custom .form-check-input {
//     margin: 0;
//     float: none;
//     flex-shrink: 0;
// }
.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.form-check .form-check-input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.form-check-input[type=radio] {
    border-radius: 50%;
}

.form-check .form-check-input {
    float: left;
    margin-left: -2.25rem;
}

.form-check-input {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -0.125rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    color-adjust: exact;
    transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.custom-radio-btn:not(.custom-radio-btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
}

.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default {
    border-width: 1px;
    border-style: dashed !important;
    color: #7e8299;
    border-color: #e4e6ef;
}

.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed {
    border-width: 1px;
    border-style: dashed;
}

.custom-radio-btn {
    outline: 0 !important;
}

.custom-radio-btn-check:active+.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default,
.custom-radio-btn-check:checked+.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default,
.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default.active,
.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default.show,
.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default:active:not(.custom-radio-btn-active),
.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default:focus:not(.custom-radio-btn-active),
.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default:hover:not(.custom-radio-btn-active),
.show>.custom-radio-btn.custom-radio-btn-outline.custom-radio-btn-outline-dashed.custom-radio-btn-outline-default {
    color: #3758ff;
    border-color: #3758ff;
    background-color: #3758ff1c !important;
}

.text-start {
    text-align: left !important;
}

.p-6 {
    padding: 1.5rem !important;
}

.d-flex {
    display: flex !important;
}

.custom-radio-btn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 0.475rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

// .label {
//     display: inline-block;
// }

.ms-5 {
    margin-left: 1.25rem !important;
}

.text-gray-800 {
    color: #3f4254 !important;
}

.fw-bolder {
    font-weight: 600 !important;
}

.fs-4 {
    font-size: 1.25rem !important;
}

.d-block {
    display: block !important;
}

.noUi-target.noUi-sm {
    height: 6px;
}

.noUi-target.noUi-horizontal {
    height: 15px;
}

.ms-5 {
    margin-left: 1.25rem !important;
}

.me-8 {
    margin-right: 2rem !important;
}

.w-100 {
    width: 100% !important;
}

.noUi-target {
    border: 0;
    background: #f5f8fa;
    box-shadow: none;
}

.noUi-target {
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-horizontal {
    height: 18px;
}

.noUi-target {
    position: relative;
}

.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.noUi-connects {
    border-radius: 3px;
}

.noUi-connects {
    overflow: hidden;
    z-index: 0;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

.noUi-target.noUi-sm .noUi-handle {
    width: 20px;
    height: 20px;
    top: -7px;
}

.noUi-target.noUi-horizontal .noUi-handle {
    width: 24px;
    height: 24px;
    top: -4.5px;
    border-radius: 50%;
    outline: 0;
}

.noUi-target .noUi-handle {
    border: 1px solid #eff2f5;
    // box-shadow: 0 3px 6px -3px rgb(0 0 0 / 70%);
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px;
}

.noUi-handle {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}

.noUi-target.noUi-sm .noUi-handle {
    width: 20px;
    height: 20px;
    top: -7px;
}

.noUi-target.noUi-horizontal .noUi-handle {
    width: 24px;
    height: 24px;
    top: -4.5px;
    border-radius: 50%;
    outline: 0;
}

.noUi-target .noUi-handle {
    border: 1px solid #eff2f5;
    // box-shadow: 0 3px 6px -3px rgb(0 0 0 / 70%);
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px;
}

.noUi-handle {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}


.noUi-target .noUi-connect {
    background: #009ef7;
}

.noUi-connect {
    background: #3fb8af;
}

.mb-7 {
    margin-bottom: 1.75rem !important;
}

.gap-9 {
    gap: 2.25rem !important;
}

.fw-bolder {
    font-weight: 600 !important;
}

.fs-4 {
    font-size: 1.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

@media (min-width: 1200px) {
    .fs-3x {
        font-size: 3rem !important;
    }
}

.fw-bolder {
    font-weight: 600 !important;
}

.noUi-target.noUi-sm {
    height: 6px;
}

// .noUi-target.noUi-horizontal {
//     height: 15px;
// }

.noUi-target {
    border: 0;
    background: #f5f8fa;
    box-shadow: none;
}

.noUi-target {
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-horizontal {
    height: 18px;
}

.noUi-target {
    position: relative;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

.scroll-y {
    overflow-y: scroll;
    position: relative;
}

.scroll-y::-webkit-scrollbar {
    display: none;
}

.pe-7 {
    padding-right: 1.75rem !important;
}

.me-n7 {
    margin-right: -1.75rem !important;
}

.mh-300px {
    max-height: 300px !important;
}

.border-dashed {
    border-style: dashed !important;
    border-color: #e4e6ef;
}

.border-left-0 {
    border-left-width: 0 !important;
}

.border-right-0 {
    border-right-width: 0 !important;
}

.border-top-0 {
    border-top-width: 0 !important;
}

.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border {
    border: 1px solid #eff2f5 !important;
}

.min-w-100px {
    min-width: 100px !important;
}

.form-select.form-select-solid {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease, background-color .2s ease;
}

.form-select {
    box-shadow: none !important;
}

.form-select-sm {
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
    padding-left: 0.75rem;
    font-size: .925rem;
    border-radius: 0.475rem;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.75rem 3rem 0.75rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5e6278;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 12px;
    border: 1px solid #e4e6ef;
    border-radius: 0.475rem;
    // box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.form-label {
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
    font-weight: 500;
    color: #3f4254;
}

.form-check.form-check-custom {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
}

.form-check.form-check-custom .form-check-input {
    margin: 0;
    float: none;
    flex-shrink: 0;
}

.form-check.form-check-custom .form-check-label {
    margin-left: 3.00rem;
}

.dropdown-custom-container {
    margin-right: -15px;
    /* left: -12px; */
    position: relative;
    right: 11px;

}

.custom-search-status {
    color: #fd397a !important;
    background-color: #ffdce7 !important;
}


.svg-color {
    transition: fill .3s ease;
    fill: #3758ff;
}

.form-check-input:checked {
    background-color: #5d78ff;
    border-color: #5d78ff;
}

.h-6px {
    height: 6px !important;
}

.w-6px {
    width: 6px !important;
}

.translate-middle {
    transform: translate(-50%, -50%) !important;
}

.start-50 {
    left: 50% !important;
}

@keyframes glowingNotify {
    0% {
        background-color: white;
        box-shadow: 0 0 0px red;
    }

    50% {
        background-color: white;
        box-shadow: 0 0 0px red;
    }

    100% {
        background-color: red;
        box-shadow: 0 0 0px red;
    }
}

@keyframes glowingAdAccountBtn {
    0% {
        background-color: white;
        box-shadow: 0 0 0px #4AB58E;
    }

    // 50% {
    //     background-color: white;
    //     box-shadow: 0 0 0px #4AB58E;
    //     color: white;
    // }

    100% {
        background-color: #4AB58E;
        box-shadow: 0 0 0px #4AB58E;
    }
}



.blinkNotification {
    animation: glowingNotify 1300ms infinite;
}

.blinkAdAccountBtn {
    animation: glowingAdAccountBtn 1300ms infinite;
}


.blinkNotificationStop {
    display: none;
}

.tax-id-label {
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
}

.custom-select {
    // margin-bottom: 1em;
    // padding: .25em;
    // border: 0;
    // border-bottom: 2px solid currentcolor; 
    // font-weight: bold;
    -webkit-appearance: none;
    // letter-spacing: .15em;
    // border-radius: 0;
    // &:focus, &:active {
    //   outline: 0;
    //   border-bottom-color: red;
    // }
    position: static;
}

.support-text {
    background-color: #5865D8 !important;
    border-color: #5865D8 !important;
}

.custom-modal-style {
    margin: 0;
    padding: 0 1rem 0 0;
    font-size: 1.2rem !important;
    font-weight: 500;
    color: #434349;
    display: flex;
    align-items: center;
}

.irs--flat .irs-bar {
    background-color: #5d78ff !important;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #5d78ff !important;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    background-color: #5d78ff !important;
}

.irs--flat .irs-handle>i:first-child {
    background-color: #5d78ff !important;
}

.custom-label {
    background-color: #5d78ff !important;
}

.text-center {
    text-align: center !important;
}

.pb-12 {
    padding-bottom: 3rem !important;
}

.mh-200px {
    max-height: 200px !important;
}

.red-text {
    color: red !important;
}

.subHeader-custom {
    padding-top: 13.25px !important;
    padding-bottom: 13.25px !important;
    position: sticky !important;
    margin-top: -75px !important
}

.feedback-btn:hover {
    color: #fff !important;
    background-color: #FD397A !important;
}

@media (max-width: 1024px) {
    .kt-header-base-light .kt-header__topbar {
        top: 50px;
    }

    // .kt-header-menu-light .kt-header-menu-mobile {
    //     display: none;
    // }

    .subHeader-custom {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        position: sticky !important;
        margin-top: 0px !important
    }
}



@media (max-width: 991.98px) {



    .kt-header-menu.kt-header-menu-on {
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        left: 0 !important;
    }

    .kt-header-menu {
        background: #ffffff !important;
        -webkit-box-shadow: 0px 1px 9px -3px black;
        // box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 75%);
    }

    .kt-header-menu {
        z-index: 1001 !important;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        left: -295px !important;
        width: 275px !important;
    }

    .kt-header-menu {
        overflow: auto !important;
    }
}

.kt-header-menu .kt-menu__item .kt-menu-item-hover:hover {
    & span.kt-menu__link-text {
        color: #FFFFFF !important;
    }
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
}

.kt-option {
    gap: 8px;

    .form-check {
        padding: 0;

        .form-check-input {
            float: none;
            margin: 0;
        }
    }
}

.upgrades-banner-icon {
    position: absolute;
    top: 24px;
    right: 2px;
    font-size: 32px;
    opacity: 1;
    transform: translateY(-50%);
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: #fff;
    background-color: transparent;
    border: 0;
    float: right;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    outline: none !important;
    text-transform: none;
    overflow: visible;

    &:hover {
        span {
            color: #000;
        }
    }
}

.dropdown-sorting .dropdown-menu.show {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    transform: none !important;
}

.dropdown-sorting-modal .dropdown-menu.show {
    margin-top: 32px !important;
    margin-bottom: 40px !important;
    transform: none !important;
}

.topBarButttonText.kt-menu__link-text2 {
    color: #959cb6 !important;
    font-weight: 500;
}

.subHeaderButton.support-btn2 {
    color: #959cb6 !important;
    font-weight: 500;
    border: 0;
    background: #f2f3f7 !important;
}

.subHeaderHeartSvgIcon.subHeaderCss {
    .red-heart-svg svg g [fill] {
        fill: #959cb6 !important;
    }
}

.subHeaderUserSvgIcon.kt-svg-icon g [fill] {
    fill: #959cb6 !important;
}

.topbarSvgFlowerIcon.kt-header__topbar-icon svg g [fill] {
    fill: #959cb6 !important;
}

.topbarSvgWaitingIcon.kt-header__topbar-icon svg g [fill] {
    fill: #959cb6 !important;
}

.topbarSvgHistoryIcon.kt-header__topbar-icon svg g [fill] {
    fill: #959cb6 !important;
}

.topbarSvgCompilingIcon.kt-header__topbar-icon svg g [fill] {
    fill: #959cb6 !important;
}

.topbarSvgUserPIcon.kt-svg-icon g [fill] {
    fill: #959cb6 !important;
}

// Home Button Icon CSS //
.home-icon span i {
    font-size: 16px !important;
    color: #5d78ff !important;
}

.kt-menu-item-hover:hover {
    .home-icon span i {
        color: #FFFFFF !important;
    }
}

// React-flags-select CSS //
span.ReactFlagsSelect-module_label__27pw9 {
    font-size: 1rem;
}

div.ReactFlagsSelect-module_filterBox__3m8EU {
    font-size: 1rem;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
    border-radius: 4px;
    height: 30px;
    border: 1px solid rgb(226, 229, 236);
}

.country-menu-flags-button {
    border: 1px solid rgb(226, 229, 236) !important;
}

.country-menu-flags-button:focus {
    border-color: #A0ACFC !important;
}

.ReactFlagsSelect-module_disabledBtn__3A4GF {
    background: #f7f8fa !important;
}

// Selected Page Button Style //
.selected-button {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    span i {
        color: #FFFFFF !important;
    }
    span {
        color: #FFFFFF !important;
    }
}
// Background Blue Gradient Color Style
.bg-blue-gradient {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    border: 0 !important;
    color: #FFFFFF !important;
}

.bg-blue-gradient-hover {
    border: 0 !important;
}

.bg-blue-gradient-hover:hover {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    border: 0 !important;
    color: #FFFFFF !important;
}

.bg-blue-gradient-dropdown button {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    border: 0 !important;
    color: #FFFFFF !important;
}

.dropdown-item:active {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
}

.dropdown_parent .dropdown-toggle::after {
    color: #5867dd !important;
    font-weight: 400 !important;
    font-size: 10px !important;
}
// ----------calender--------------------------------
.fc .fc-button-primary:hover {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    color: #fff;
    border: 0px;
    font-weight: 500;
}

.fc .fc-button-primary:disabled {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    color: #fff;
    border: 0px;
    font-weight: 500;
    opacity: 1;
}

.fc .fc-button:disabled {
    opacity: 1;
}

.fc .fc-button {
    border-radius: 0px;
}

.fc .fc-button-primary {
    box-shadow: none !important;
    background: #f2f3f7;
    color: #959cb6;
    border: 0px;
    font-weight: 500;
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    background: #f2f3f7;
    border: 0px;
    font-weight: 500;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) !important;
    color: #fff;
    border: 0px;
    font-weight: 500;
}

.fc-h-event {
    border: none;
    background-color: #fff;
}

.fc .fc-daygrid-day-number {
    font-weight: 500;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background: none;
}

.fc .fc-col-header-cell-cushion {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.fc-day-today {
    /* background-color: #e1edf7 !important; */
    // background: linear-gradient(150deg, rgba(70, 190, 247, 0.5) 0%, rgba(83, 133, 225, 0.5) 100%) !important;
    background: linear-gradient(150deg, rgba(80, 200, 255, 0.5) 0%, rgba(93, 143, 235, 0.5) 100%) !important;
    padding-left: 0.5 rem;
    padding-right: 0.5 rem;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 0.7rem;
    margin-top: 0.7rem;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0.7rem;
    margin-bottom: 0.7rem;
}

.fc-timeGridWeek-view .my-week-view-style {
    .fc .fc-scrollgrid-section-liquid>td {
        height: 0%;
    }
}

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
    height: 675px;
}

.fc .fc-timegrid-body {
    display: none;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 2.3rem;
    margin-top: 1.55rem;
}

/* ----------------custom class------------------ */
.bg-custom {
    background-color: #f2f3f7;
    color: #5d78ff;
    font-family: inherit;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.bg-custom:hover {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    color: white;
    font-family: Poppins, Helvetica, sans-serif;
    .iconColor {
        color: white;
    }
}

.bg-custom2 {
    background-color: #fff;
    font-family: Poppins, Helvetica, sans-serif;
}

.cont-custom-3 {
    height: 59.8px;
    color: #5d78ff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.all-font-size {
    font-size: 1em;
}

.note-icon-class {
    color: #fff;
    background-color: #5d78ff;
    font-size: 0.95em;
    border-radius: 0.2em;
}

/* .note-icon-class :hover{
    color: #fff;
    background-color: #5d78ff;
    font-size: 0.95em;
    border-radius: 0.2em;
} */

.facebook-icon-class {
    color: #0d8af0;
}

.monthBgColor:hover {
    .facebook-icon-class {
        color: white;
    }
}

.monthBgColor {
    background-color: #f2f3f7;
    color: #5d78ff;
}

.monthBgColor:hover {
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    color: white;
}

// ----------calender--------------------------------

.customButton {
    background: #f2f3f7 ;
    color: #959cb6 ;
}

.customButton :hover {
    color: #ffffff !important ;
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%) ;
}

.ad-ticket-view-modal.modal.show,
.ad-ads-modal-view.modal.show {
    z-index: 1040;
}

.modal.show.ad-follow-up-modal .modal-dialog,
.ad-select-input-modal .modal-dialog {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-refresh-ai-text {
    cursor: pointer;
    font-size: 1rem !important;
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%); 
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
}

.ad-refresh-ai-text-disabled  {
    cursor: not-allowed;
    font-size: 1rem !important; 
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%); 
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
}

.ad-choose-healine-text {
    margin-left: 5px;
    @extend .ad-refresh-ai-text;
}

.ad-ticket-view-description {
    margin-bottom: 20px;
}

blockquote p::before {
content: '\201C';
}
  
blockquote p::after {
content: '\201D';
}

body{
    --ck-z-default: 100;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

.ck-body-wrapper{ z-index: 1300 !important;}
.ck-body-wrapper i{
    font-size: inherit;
}

.table-cell-hover {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.table-cell-hover:hover {
   color: #2492d1; 
}
  
.sales-connect-links {
    color: inherit;
    text-decoration: none; 
    transition: color 0.3s ease;
}

.sales-connect-links:hover {
    color: #3699FF; 
    font-weight: 400;
}

.ad-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #6c757d;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -125px; 
    opacity: 0;
    transition: opacity 0.3s;
    text-wrap: wrap;
}

.ad-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.portlet-wrapper {
    position: relative;
}

.portlet-overlay{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.portlet-overlay-content{
    color: #000000;
    text-align: center;
    margin-top: 45px;
}

.portlet-overlay-content .box{
    position: relative;
    background: #ffffff;
    width: 100%;
    height: max-content;
    border: 1.5px solid #5385e1;
    border-radius: 4px;
    padding: 30px;
}
.portlet-overlay-content .salesbox{
    position: relative;
    background: #ffffff;
    width: 100%;
    height: max-content;
    border: 1.5px solid #5385e1;
    border-radius: 4px;
    padding: 20px;
}

.dot-stream-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 0 15px;
    height: 34px;
}
  
.dot-stream {
    display: flex;
    gap: 8px;
}
  
.dot-stream span {
    width: 2px;
    height: 20px;
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    border-radius: 15px;
    animation: dot-stream 1.2s infinite ease-in-out;
}
  
.dot-stream span:nth-child(1) {
    animation-delay: 0s;
}
  
.dot-stream span:nth-child(2) {
    animation-delay: 0.2s;
}
  
.dot-stream span:nth-child(3) {
    animation-delay: 0.4s;
}

.dot-stream span:nth-child(4) {
    animation-delay: 0.6s;
}
  
  /* Animation for the bouncing effect */
@keyframes dot-stream {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(0.2);
      opacity: 0.3;
    }
}  

.dropdown-toogle-button {
    color: rgb(93, 120, 255) !important;
    background-color: rgba(77, 89, 149, 0.06) !important;
    height: 36.4px !important;
    border-width: 0 !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 1 !important;
}

.kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-wizard-v4__content .billing-table{
    overflow: auto;
}

.tablet-error-icon.de{
    position: absolute;
    left: 145px;
    bottom: 41px;
}

.tablet-error-icon.default{
    position: absolute;
    left: 170px;
    bottom: 42px;
}

.coupon-alignment{
    display: flex !important;
    margin-bottom: 20px;
}

.coupon-button{
    position: unset !important;
    margin-left: 5px !important;
}

.ck.ck-dropdown .ck-dropdown__arrow{
    z-index: 1 !important;
}

.logo-dashboard{
    padding: 5rem !important;
}

.dropdown-menu.show {
    // height: 100vh;
    overflow: auto;
}

.facebook-action-responsive{
    padding: 30px 0 !important;
}

.media-toogle-button{
    width: max-content;
}

.responsive-date-alignment{
    display: block;
    width: 100%;
    text-wrap: wrap;
}

.custom-left-dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    z-index: 1 !important;
    height: 20vh;
    overflow-y: auto;
    width: 30rem;
}

.custom-dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    z-index: 1 !important;
    height: 20vh;
    overflow-y: auto;
    width: 30rem;
}

.custom-left-dropdown-view .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    z-index: 1 !important;
    height: 20vh;
    overflow-y: auto;
    width: 30rem;
}

.custom-create-dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    z-index: 1 !important;
    height: 20vh;
    overflow-y: auto;
    width: 30rem;
}

.custom-view-dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    z-index: 1 !important;
    height: 20vh;
    overflow-y: auto;
    width: 30rem;
}

.daterangepicker .drp-calendar td.today {
    background: white !important;
    color: #5385e1 !important;
    outline: 2px solid #5385e1 !important;
    font-weight: 500 !important;
}

.daterangepicker .drp-calendar td.active.start-date{
    border-radius: 4px;
    background: linear-gradient(150deg, #46bef7, #5385e1) !important;
    color: #ffffff !important;
    outline: none !important;
}

.daterangepicker .drp-calendar td.active.start-date.end-date {
    border-radius: 4px;
    background: linear-gradient(150deg, #46bef7, #5385e1) !important;
    color: #ffffff !important;
    outline: none !important;
}

.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
    background-color: #ebebeb !important;
    color: #595d6e;
}

.custom-banner {
    display: flex;
    margin: 0 0.2rem 20px 0.2rem;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.shared-message-button{
   background-color: white !important;
   color: #7E8299 !important;
   height: auto !important;
   font-size: 13px !important;
   text-shadow: none !important;
   text-wrap: nowrap !important;
}

.upgrades-banner-icon {
    position: unset;
    transform: none;
}

.decision-button{
    background-color: white !important;
    color: #7E8299 !important;
    height: 34px !important;
    font-size: 13px !important;
    top: 0 !important;
    right: 0 !important;
    position: relative !important;
    transform: none !important;
}

.shared-message-button:hover span {
    background: linear-gradient(150deg, rgb(70, 190, 247) 0%, rgb(83, 133, 225) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
}

// ---------- Responsive Mobile and Tablet -----------
@media screen and (max-width: 320px){
    // ----- Adspace Box -----
    .adscard {
        padding: 0 2px !important;
    }
}

@media screen and (max-width: 500px){
    // ----- Support -----
    .kt-sc-2 .kt-sc__bottom {
        padding: 25px 20px 0 !important;
    }
}

@media screen and (max-width: 767px) {
    // ----- Login Page -----
    .kt-login.kt-login--v1 .kt-login__aside .kt-login__subtitle {
        margin: 1rem 0;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__head {
        text-align: center;
        padding: 0;
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body {
        margin-top: 2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__title{
        margin-bottom: 0 !important;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-form {
        margin: 1rem auto;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__actions {
        margin: 1rem 0;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-form .form-group label {
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__aside .kt-login__info {
        margin-top: 2rem;
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__actions .kt-login__link-forgot {
        font-size: 1.2rem;
    }

    // ----- Forgot Password Page -----
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__title > h3.forgotpasswordclass {
        font-size: 1.6rem;
    }

    // ----- Register Page -----
    #register .form-group label{
        font-size: 1.2rem;
    }

    // ----- Topbar -----
    .kt-subheader .kt-container {
        flex-direction: column;
    }

    .kt-subheader .kt-subheader__toolbar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    // ----- Main Sidebar -----
    .kt-aside--on .kt-aside-close {
        transition: left 0.3s ease, right 0.3s ease;
        left: 236px;
        top: 15px;
    }

    // ----- New User Profile -----
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .kt-form-inner {
        width: 100% !important;
        margin: 0 auto;
    }
    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form {
        width: 100%;
        padding: 0;
    }
    .mobile-payment-checkbox{
        margin-bottom: 10px;
    }
    .braintree-sheet__content--form .braintree-form__field-group {
        width: 100% !important;
        padding: 0px 8px !important;
    }

    // ----- Banners -----
    // .mobilebanner{
    //     max-width: 100% !important;
    // }

    // ----- Profile Page -----
    .nav-5-items .kt-wizard-v4__nav-item {
        flex-basis: calc(20% - 0.5rem) !important;
        width: 100% !important;
    }

    .qualification-upgrade-checkbox{
        flex-direction: column;
    }

    .qualification-upgrade-checkbox-margin{
        margin-bottom: 8px;
    }

    // ----- Member Page -----
    .action-alignment{
        display: flex;
    }

    .member-input-margin{
        margin-bottom: 2rem;
    }

    // ----- Billing Page -----
    .billing-heading{
        font-size: 1.1rem !important;
    }

    .billing-button{
        margin-top: 10px
    }

    .billing-table{
        width: 100% !important;
        overflow: auto;
    }

    .billing-table thead th{
        text-wrap: nowrap;
    }

    // ----- Upgrade Page -----
    .upgrade-button-alignment{
        flex-direction: column !important;
        align-items: center !important;
    }

    .upgrade-button-margin{
        margin: 0 auto !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .coupon-alignment{
        display: flex !important;
        margin-bottom: 20px;
    }

    .coupon-button{
        position: unset !important;
        margin-left: 5px !important;
    }

    .coupon-Error-text{
        position: unset !important;
        margin-top: -12px !important;
    }

    .error-icon{
        position: unset !important;
        margin: auto 0 auto 5px;
    }

    // ----- Tutorial -----
    .tutorial-class{
        display: none !important;
    }

    // ----- Dashboard -----
    .logo-dashboard{
        padding: 3rem !important;
    }

    // ----- Database -----
    .competitor-responsive{
        flex-direction: column;
    }

    .competitor-responsive-heading{
        margin: 10px 0;
    }

    .competitor-responsive-button{
        margin-bottom: 10px;
    }

    .custom-left-dropdown .dropdown-menu {
        right: 0 !important;
        left: -55px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 38vw;
    }
}

@media screen and (max-width: 768px){
    // ----- New User Profile -----
    .mobile-payment-checkbox{
        margin-bottom: 10px;
    }
    .braintree-sheet__content--form .braintree-form__field-group {
        width: 100% !important;
        padding: 0px 8px !important;
    }
    .braintree-sheet__content--form .braintree-form__flexible-fields {
        display: block;
    }

    // ----- Banners -----
    // .mobilebanner{
    //     max-width: 100% !important;
    // }

    // ----- Profile Page -----
    .nav-5-items .kt-wizard-v4__nav-item {
        flex-basis: calc(20% - 0.5rem) !important;
        width: 100% !important;
    }

    .kt-wizard-v4 .kt-wizard-v4__wrapper .kt-form .profile-form {
        width: 100% !important;
        margin: 0 auto;
    }

    .payment-box{
        width: 50% !important;
    }

    // ----- Member Page -----
    .member-input-margin{
        margin-bottom: 2rem;
    }

    // ----- Billing Page -----
    .billing-table{
        width: 100% !important;
        overflow: auto;
    }
    .billing-table thead th{
        text-wrap: nowrap;
    }

    // ----- Tutorial -----
    .tutorial-class{
        display: none !important;
    }

    // ----- Support ------
    .kt-sc-2 .kt-sc__bottom .nav-tabs.nav-tabs-line{
        padding: 0 !important;
    }

    .hide-tutorial {
        display: none !important;
    }

    // ----- Adwizard -----
    .adwizard-responsive{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .adwizard-form{
        padding: 0 !important;
    }

    // ----- Adwizard -----
    .adwizard-responsive{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .responsive-goggleads-address{
        flex-direction: column !important;
    }

    // ----- Editor -----
    .editor-responsive-checkbox{
        margin-bottom: 10px;
    }

    .kt-heading.kt-heading--md {
        font-size: 1.2rem !important;
    }

    // ----- Sales Connect -----
    .salesconnect-filter-responsive{
        flex-direction: column;
    }

    .salesconnect-responsive-heading{
        margin: 10px;
    }

    .filter-responsive{
        margin-bottom: 5px;
    }

    .import-responsive{
        margin-bottom: 5px;
    }

    .addcompany-responsive{
        margin-bottom: 5px;
    }

    .export-responsive{
        margin-bottom: 5px;
    }

    // ----- Social Center Page -----
    .facebook-action-responsive{
        padding: 40px 0 !important;
    }

    // ----- Database -----
    .url-responsive-field{
        min-width: 100% !important;
    }

    // ----- Adspace -----
    .adspace-dropdown-responsive{
        width: 50% !important;
    }

    .adspace-main-responsive{
        width: 100% !important;
    }

    .adspace-slider-responsive{
        width: 336px !important;
        padding: 0 !important;
    }

    // ----- Topbar -----
    .mobile-home-icon{
        width: 100% !important;
    }

    .mobile-home-icon-responsive{
        margin-top: 5.5px !important;
    }

    .mobile-toogle-responsive{
        width: 100% !important;
        text-align: left !important;
        padding: 9px 30px !important;
    }

    // ----- Activity ------
    .facebook-post-responsive-drawer {
        width: 100% !important;
        height: 100vh;
    }

    .facebook-post-responsive-title{
        margin-left: 0.5rem !important
    }

    .dropdown-sorting-modal .dropdown-menu.show{
        // right: 0 !important;
        // left: auto !important;
        width: 60vw !important;
    }

    .MuiDrawer-paperAnchorRight {
        right: 0 !important;
        left: auto !important;
    }

    .new-qualification-checkbox{
        flex-direction: column;
    }

    .new-qualification-checkbox-margin{
        margin-bottom: 8px;
    }

    .new-qualification-checkbox-width{
        width: 100% !important;
        max-width: 100% !important;
    }

    .responsive-popup{
        flex-direction: column !important;
    }

    .todo-svg{
        width: 150px !important;
        height: 150px !important;
    }
}

@media screen and (max-width: 1024px){
    // ----- Subheader -----
    .kt-header-menu.kt-header-menu-on {
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        left: 0 !important;
    }

    .kt-header-menu {
        background: #ffffff !important;
        -webkit-box-shadow: 0px 1px 9px -3px black;
        // box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 75%);
    }

    .kt-header-menu {
        z-index: 1001 !important;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease !important;
        left: -295px !important;
        width: 230px !important;
    }

    .kt-header-menu {
        overflow: auto !important;
    }
}

@media screen and (min-width: 1025px){
    .qualification-new-form{
        width: 80% !important;
    }
}

@media screen and (min-width: 1201px){
    // ----- Dashboard -----
    .logo-dashboard {
        padding: 1rem !important;
    }
}

@media screen and (min-width: 1301px){
    // ----- Adspace -----
    .adspace-main-responsive{
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        padding: 0 10px;
        width: 100%;
    }

    .adspace-slider-responsive{
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        padding: 0 10px;
        width: 100%;
    }
}

@media screen and (min-width: 1920px) {
    .mobile-adc-logo{
        max-width: 80% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 374px){
    // ----- Memeber Page -----
    .mobile-heading{
        font-size: 0.9rem !important;
    }

    // ----- Dashboard -----
    .logo-dashboard{
        padding: 1rem !important;
    }

    // ----- Support -----
    .email-responsive{
        font-size: 1rem !important;
    }

    .ticket-font{
        font-size: 18px !important;
    }

    .responsive-follow-date{
        width: 100%;
        margin-left: 0 !important;
        margin-top: 10px !important
    }

    .responsive-checkbox{
        flex-direction: column;
    }

    .custom-checkbox-class{
        margin-bottom: 10px;
    }

    // ----- Assistant -----
    .responsive-input{
        max-width: 100% !important;
    }

    .responsive-input-arrow{
        max-width: 10% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 380px){
    // ----- Postwizard -----
    .postwizard-responsive-button{
        display: flex;
        flex-wrap: wrap;
    }

    .template-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }

    .responsive-ai-button{
        margin-top: 5px;
    }

    .responsive-button-height{
        height: 26px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 425px){
    .competitor-responsive-button{
        flex-direction: column;
        align-items: flex-start !important;
    }

    .competitor-remove-button{
        margin-left: 0 !important;
        margin-bottom: 5px !important;
    }

    .competitor-new-button{
        margin-bottom: 5px !important;
    }

    .competitor-start-button{
        margin-bottom: 5px !important;
    }

    .competitor-responsive-heading{
        align-items: flex-start !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 500px){
    // ----- Support -----
    .kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item .nav-link, .kt-sc-2 .kt-sc__bottom .nav-tabs .nav-item a.nav-link{
        padding: 0 0 1rem !important;
        margin-bottom: 25px !important;
    }

    .mobile-responsive-search{
        flex-direction: column;
    }

    .mobile-responsive-search-margin{
        margin-bottom: 10px;
    }

    .dropdown-responsive-margin{
        margin-bottom: 15px;
    }

    .custom-responsive-margin{
        margin-bottom: 15px;
    }

    // ----- Refer Page -----
    .refer-responsive{
        flex-direction: column;
        padding: 10px !important;
    }

    .refer-heading{
        margin-bottom: 10px !important;
    }

    // ----- Calender -----
    .fc .fc-toolbar{
        flex-direction: column;
    }

    .fc-toolbar-chunk{
        margin-bottom: 10px;
    }

    .custom-left-dropdown .dropdown-menu {
        right: 0 !important;
        left: -32px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-dropdown .dropdown-menu {
        right: 0 !important;
        left: -8px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-left-dropdown-view .dropdown-menu {
        right: 0 !important;
        left: -20px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-create-dropdown .dropdown-menu {
        right: 0 !important;
        left: -145px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-view-dropdown .dropdown-menu {
        right: 0 !important;
        left: auto !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .todo-column{
        flex-direction: column !important;
        gap: 10px;
    }
}

@media screen and (min-width: 320px) and (max-width: 545px){
    // ----- Integrations -----
    .admin-responsive-button{
        flex-direction: column;
        align-items: flex-start !important;
    }

    .responsive-payment-button{
        margin-bottom: 10px;
    }

    .responsive-remove-button{
        margin-bottom: 10px;
        margin-left: 0px !important;
    }

    .responsive-hidden-button{
        margin-bottom: 10px;
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 576px){
    // ----- Integration Pages -----
    .kt-callout__body{
        flex-direction: column;
    }

    .integration-responsive-content{
        text-align: center;
        margin: 20px 0;
    }

    .connectaccount-responsive{
        flex-direction: column;
        padding: 20px !important;
    }

    .connectaccount-responsive-title{
        margin-bottom: 10px !important;
    }

    // ----- Database -----
    .ownresource-responsive{
        flex-direction: column;
    }

    .ownresource-responsive-heading{
        margin: 10px 0;
    }

    .ownresource-responsive-button{
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 349px) and (max-width: 380px){
    .responsive-ai-button{
        margin-top: 0px;
    }
}

@media screen and (min-width: 380px) and (max-width: 454px){
    .template-responsive-button{
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 374px) and (max-width: 450px){
    // ----- Support -----
    .email-responsive{
        font-size: 1rem !important;
    }

    .ticket-font{
        font-size: 20px !important;
    }

    .responsive-follow-date{
        width: 100%;
        margin-left: 0 !important;
        margin-top: 10px !important
    }

    // ----- Assistant -----
    .responsive-input{
        max-width: 100% !important;
    }

    .responsive-input-arrow{
        max-width: 10% !important;
    }
}

@media screen and (min-width: 450px) and (max-width: 650px){
    // ----- Dashboard -----
    .mobile-adc-logo{
        max-width: 70% !important;
    }

    // ----- Assistant -----
    .responsive-input{
        max-width: 100% !important;
    }

    .responsive-input-arrow{
        max-width: 8% !important;
    }
}

@media screen and (min-width: 501px) and (max-width: 601px){
    .custom-left-dropdown .dropdown-menu {
        right: 0 !important;
        left: -210px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-dropdown .dropdown-menu {
        right: 0 !important;
        left: -96px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }

    .custom-left-dropdown-view .dropdown-menu {
        right: 0 !important;
        left: -80px !important;
        z-index: 1 !important;
        height: 20vh;
        overflow-y: auto;
        width: 75vw;
    }
}

@media screen and (min-width: 650px) and (max-width: 768px){
    // ----- Dashboard -----
    .logo-dashboard {
        padding: 1rem !important;
    }
    .mobile-adc-logo{
        max-width: 50% !important;
    }

    
    // ----- Assistant -----
    .responsive-input{
        max-width: 100% !important;
    }

    .responsive-input-arrow{
        max-width: 6% !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px){
    // ----- Login Page -----
    .kt-login.kt-login--v1 .kt-login__aside .kt-login__subtitle {
        margin: 1rem 0;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__head {
        text-align: center;
        padding: 0;
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body {
        margin-top: 2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__title{
        margin-bottom: 0 !important;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-form {
        margin: 1rem auto;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__actions {
        margin: 2rem 0;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-form .form-group label {
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__aside .kt-login__info {
        margin-top: 2rem;
        font-size: 1.2rem;
    }
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__actions .kt-login__link-forgot {
        font-size: 1.2rem;
    }

    // ----- Forgot Password Page -----
    .kt-login.kt-login--v1 .kt-login__wrapper .kt-login__body .kt-login__form .kt-login__title > h3.forgotpasswordclass {
        font-size: 1.6rem;
    }

    // ----- Register Page -----
    #register .form-group label{
        font-size: 1.2rem;
    }

    // ----- Main Sidebar -----
    .kt-aside--on .kt-aside-close {
        transition: left 0.3s ease, right 0.3s ease;
        left: 236px;
        top: 15px;
    }

    // ----- Billing Page -----
    .billing-table thead th{
        text-wrap: nowrap;
    }

    // ----- Upgrade Page -----
    .tablet-error-icon.de{
        position: absolute !important;
        left: 145px !important;
        bottom: 39px !important;
    }

    .tablet-error-icon.default{
        position: absolute !important;
        left: 170px !important;
        bottom: 39px !important;
    }

    .coupon-alignment{
        display: flex !important;
        margin-bottom: 20px;
    }

    .coupon-button{
        position: unset !important;
        margin-left: 5px !important;
    }

    // ----- Tutorial -----
    .tutorial-class{
        display: none !important;
    }

    // ----- Dashboard -----
    .mobile-adc-logo{
        max-width: 40% !important;
    }

    .logo-dashboard{
        padding: 1rem !important;
    }

    // ----- Support -----
    .hide-tutorial {
        display: none !important;
    }

    // ----- Adwizard -----
    .adwizard-responsive{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .adwizard-form{
        padding: 0 !important;
    }

    // ----- Social Center Page -----
    .facebook-action-responsive{
        padding: 40px 0 !important;
    }

    // ----- Adspace -----
    .adspace-main-responsive{
        width: 100% !important;
    }

    .adspace-slider-responsive{
        width: 336px !important;
        padding: 0 !important;
    }

    // ----- Topbar -----
    .mobile-home-icon{
        width: 100% !important;
    }

    .mobile-home-icon-responsive{
        margin-top: 5.5px !important;
    }

    .mobile-toogle-responsive{
        width: 100% !important;
        text-align: left !important;
        padding: 9px 30px !important;
    }

    // ----- Activity ------

    .facebook-post-responsive-drawer {
        width: 100% !important;
        height: 100vh;
    }

    .facebook-post-responsive-title{
        margin-left: 0.5rem !important
    }

    .new-qualification-checkbox{
        flex-direction: column;
    }

    .new-qualification-checkbox-margin{
        margin-bottom: 8px;
    }

    .new-qualification-checkbox-width{
        width: 100% !important;
        max-width: 100% !important;
    }

    .responsive-popup{
        flex-direction: column !important;
    }

    .todo-svg{
        width: 200px !important;
        height: 200px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 895px){
    // ----- Upgrade Page -----
    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number{
        margin-right: 0.5rem !important;
    }

    .billing-tab-title {
        font-size: 0.8rem !important;
    }

    .billing-number-box {
        font-size: 0.85rem !important;
        flex: 0 0 1.5rem !important;
        height: 1.5rem !important;
        width: 1.5rem !important;
    }

    .billing-tab {
        padding: 0.55rem !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
    // ----- Billing Page -----
    .billing-number-box{
        font-size: 1rem !important;
        flex: 0 0 2rem !important;
        height: 2rem !important;
        width: 2rem !important;
    }

    .billing-tab-title{
        font-size: 0.85rem !important;
    }

    .billing-tab{
        padding: 0.85rem !important;
    }

}

@media screen and (min-width: 769px) and (max-width: 845px){
    // ----- Profile Page -----
    .profile-tab{
        padding: 0.8rem !important;
    }
    
    .profile-number-box{
        font-size: 1rem !important;
        flex: 0 0 2rem !important;
        height: 2rem !important;
        width: 2rem !important;
    }

    .profile-title{
        font-size: 0.85rem !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 990px){
    // ----- New User Profile -----
    .mobile-payment-checkbox{
        margin-bottom: 10px;
    }

    // ----- Member Page -----
    .member-input-margin{
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1250px){
    // ----- New User Profile -----
   .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-label .kt-wizard-v4__nav-label-title{
       font-size: 1rem;
   }

   .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body {
       padding: 1rem;
   }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .mobile-adc-logo{
        max-width: 40% !important;
    }

    // ----- Social Center Page -----
    .facebook-action-responsive{
        padding: 40px 0 !important;
    }

    .todo-svg{
        width: 200px !important;
        height: 200px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
    // ----- Integration Page -----
    .kt-callout .kt-callout__body {
        flex-direction: column;
    }

    .integration-tablet-responsive{
        margin-bottom: 15px;
    }

    .googlemerchant-responsive-title{
        font-size: 1.2rem !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1058px){
    // ----- Upgrade Page -----
    .kt-wizard-v4 .kt-wizard-v4__nav .kt-wizard-v4__nav-items .kt-wizard-v4__nav-item .kt-wizard-v4__nav-body .kt-wizard-v4__nav-number{
        margin-right: 0.5rem !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1125px){
    // ----- Topbar -----
    .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{
        font-size: 0.8rem !important;
    }

    .mobile-toggle-button{
        font-size: 0.8rem !important;
        height: 32.5px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px){
   // ----- Adwizard -----
   .adwizard-responsive{
    padding-left: 15px !important;
    padding-right: 15px !important;
    }

    .adwizard-form{
        padding: 0 !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1300px){
    // ----- Assistant -----
    .assistant-card-responsive{
        flex: 0 0 33.33333% !important;
        max-width: 33.33333% !important;
    }

    .assistant-largecard-responsive {
        flex: 0 0 66.66667% !important;
        max-width: 66.66667% !important;
    }

    // ----- Adspace -----
    .adspace-main-responsive{
        width: 100% !important;
    }

    .adspace-slider-responsive{
        width: 336px !important;
        padding: 0 !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1125px){
    .responsive-topbar-menu{
        margin: 0 0 0 14px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
    .mobile-card{
        flex-direction: column !important;
    }

    .mobile-user-margin{
        margin-bottom: 20px !important;
    }

    .mobile-user-box{
        align-self: flex-start !important;
        text-align: left !important;
    }

    .mobile-user-single-box{
        margin-left: 0 !important;
        text-align: left !important;
    }

    .row.row-full-height {
        height: 100%;
    }

    .mobile-view-card{
        height: calc(44% - 20px) !important;
    }

    .todo-card{
        height: 212px;
    }

    .campaign-row{
        margin-top: -70px;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1920px){

    .todo-card{
        height: 212px;
    }

    .todo-list-content{
        margin-top: -45px;
    }
}

@media screen and (min-width: 1920px) and (max-width: 2100px){

    .todo-card{
        height: 212px;
    }

    .todo-list-content{
        margin-top: -45px;
    }
}

@media screen and (min-width: 2100px) and (max-width: 2570px){

    .todo-card{
        height: 212px;
    }

    .todo-list-content{
        margin-top: -45px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1350px){
    .tipster-responsive-heading{
        font-size: 16px;
        margin-bottom: 5px !important;
    }

    .conditional-break {
        display: none;
    }

    .conditional-space {
        display: inline;
    }
}

.card-padding{
    padding: 2rem 2.25rem !important;
}

.chart-percentage{
    background: linear-gradient(150deg, #46bef7 0%, #5385e1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.75rem;
    font-weight: 500;
}

.todo-svg{
   width: 100%;
   height: 100%;
   margin: auto;
}

//
// Support Center/Faq 2
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Base
.kt-sc-faq-2 {
	display: flex;
	flex-direction: column;
	position: relative;

	.kt-sc__bg{
		$portlet-padding: 0;
		position: absolute;
		top:$portlet-padding;
		left:$portlet-padding;
		right: $portlet-padding;
		bottom: $portlet-padding;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		z-index: 0;
		padding-right: 70px;
		overflow:hidden;

		.kt-svg-icon{
			height: 110%;
			width: auto;
			transform: translateY(50px);
		}

		&--layer{
			top:0;
			left:0;
			right: 0;
			bottom: 0;
			padding-right: 0;

			.kt-svg-icon{
				height: auto;
				width: 100%;
				fill: rgba(103,112,153, 0.04);
				transform: translateY(0);
			}
		}
	}

	.kt-sc__top{
		position: relative;
		z-index: 1;

		.kt-sc__content{
			margin-right: 50%;
			display: flex;
			align-items: center;
			padding: 6rem 3rem;

			.kt-sc__title{
				color: kt-base-color(label, 4);
				font-size: 2.15rem;
				font-weight: 600;
				margin-right: 3.5rem;
				margin-bottom: 0;
			}

			.kt-sc__form {

				.input-group{
					width: 380px;
					box-shadow: 0px 0px 27px 0px rgba(160,160,191,0.2);

					.input-group-text{
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						border: none;
						padding: 0.65rem 2rem;
						background-color: kt-state-color(brand, inverse);
					}

					.form-control{
						border: none;
						padding: 2.5rem 0;
						font-weight: 400;
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}

@include kt-tablet-and-mobile(){

	.kt-sc-2{

		.kt-sc__bg{
			opacity: 0.2;

			.kt-svg-icon{
				transform: translateY(0);
			}
		}

		.kt-sc__top{
			min-height: auto;

			.kt-sc__content{
				flex-direction: column;
				padding: 4rem 0;
				margin-right: 0;
				min-height: auto;

				.kt-sc__title{
					margin-bottom: 2rem;
				}

				.kt-sc__form {

					.input-group{
						width: 100%;
					}
				}
			}
		}
	}
}

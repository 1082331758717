//
// Form
//



.kt-form {
	.kt-form__group {
		&.kt-form__group--inline {
			display: table;
			padding: 0;

			> .kt-form__label {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				padding-right: 1rem;

				&.kt-form__label-no-wrap {
					white-space:nowrap;
				}

				> label {
					margin: 0;
				}
			}

			> .kt-form__control {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				width: 100%;
			}
		}
	}

	// Action bar
	.kt-form__actions {
		&.kt-form__actions--right {
			text-align: right;
		}

		&.kt-form__actions--center {
			text-align: center;
		}
	}

	&.kt-form--center{
		width: 60%;
		margin: 0 auto;
	}
}

// Validation feedback
.is-valid {
	.valid-feedback {
		display: inline-block;
	}
}

.is-invalid {
	.invalid-feedback {
		display: inline-block;
	}
}

@include kt-desktop {
	.kt-form {
		&.kt-form--label-right {
			.form-group {
				label:not(.kt-checkbox):not(.kt-radio):not(.kt-option) {
					text-align: right;
				}
			}
		}
	}
}

.calendly-popup .calendly-popup-content iframe {
	height: 95vh;
}

//   Braintree Payment Module CSS   //
.braintree-sheet__content--form * {
	border-radius: 4px !important;
	border-color: #d4cfcf !important;
	font-family: 'Poppins', 'Helvetica', 'sans-serif' !important;
	font-size: 13px !important;
}

#cardholder-name ::placeholder {
	font-family: 'Poppins', 'Helvetica', 'sans-serif' !important;
}

.braintree-form__label {
	margin-bottom: 9px !important;
	color: #706e6e !important;
}

.braintree-sheet {
	border: none !important;
}

.braintree-sheet__content--form {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: space-between !important;
	margin: 0px -20px !important;
	padding-bottom: 0px;
}

.braintree-loaded .braintree-sheet__container {
    margin: 0;
}

.braintree-hidden{
	display: none;
}

.braintree-sheet__content--form [data-braintree-id="expiration-date-field-group"]{
	margin-bottom: 0px !important;
}

.braintree-sheet__content--form [data-braintree-id="cvv-field-group"]{
	margin-bottom: 0px !important;
}

.form-group label,
.braintree-form__flexible-fields {
	width: 100% !important;
}

.braintree-sheet__content--form .braintree-form__field-group {
	width: 50% !important;
	padding: 0px 8px !important
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__label {
	color: #343a40 !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error {
	color: #fd397a !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error-icon use {
	fill: #fd397a !important;
}

.braintree-sheet--has-error .braintree-sheet__error .braintree-sheet__error-text {
	color: #fd397a !important;
}

.braintree-sheet--has-error .braintree-sheet__error .braintree-sheet__error-icon use {
	fill: #fd397a !important;
}

.braintree-placeholder {
	margin: -12px !important;
}

.braintree-heading,
.braintree-sheet__header,
.braintree-form__notice-of-collection,
.braintree-large-button {
	display: none !important;
}

//   React Date-Time Module CSS   //
.rdtPicker {
	color: #595d6e;
	box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15) !important;
	background-color: #fff !important;
	border: 0 !important;
	font-family: Poppins, Helvetica, sans-serif;
}

.rdtCounter {
	width: 70px !important;
}

.dow,
.rdtTimeToggle,
.rdtSwitch {
	font-weight: 500;
	font-size: 1rem;
}

.rdtTimeToggle:hover,
.rdtPrev:hover,
.rdtNext:hover,
.rdtSwitch:hover,
.rdtBtn:hover {
	color: kt-brand-color() !important;
}

.rdtPicker td.rdtDay,
td.rdtMonth,
td.rdtYear,
.rdtTimeToggle,
.rdtPrev,
.rdtNext,
.rdtSwitch,
.rdtBtn {
	border-radius: 4px;
}

.rdtPicker td.rdtToday {
	border-radius: 4px;
	font-weight: 500;
	color: kt-brand-color() !important;
	background-color: #F6F8FA !important;
}

.rdtPicker td.rdtToday:before {
	border-bottom: 7px solid kt-brand-color() !important;
}

.rdtPicker td.rdtActive {
	font-weight: 500;
	background-color: kt-brand-color() !important;
	color: #fff !important;
}

.is-phoneinput-invalid {
	border-color: #fd397a !important;
	padding-right: calc(1.5em + 1.3rem) !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd397a' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd397a' stroke='none'/%3e%3c/svg%3e") !important;
	background-repeat: no-repeat !important;
	background-position: right calc(0.375em + 0.325rem) center !important;
	background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem) !important;
}
//
// Badge Customization
//



.badge {
    display: inline-block;
    padding: .5em .85em;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .475rem;
}

.badge.badge-sm.badge-circle {
    width: 1.5rem;
    height: 1.5rem;
}

.badge.badge-sm {
    min-width: 1.5rem;
    font-size: .85rem;
}

.badge.badge-circle {
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: 1.75rem;
}

.badge.badge-circle, .badge.badge-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;
    min-width: 1.75rem;
    padding: 0 .1rem;
}

.badge-light-warning {
    color: #ffc700;
    background-color: #fff8dd;
}
//
// Pricing Tables 2
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.kt-pricing-2 {
	padding: 0 0 2.86rem 0;

	.kt-pricing-2__head {
		text-align: center;
		background-color: #22B9FF;
		padding: 8.57rem 0 25rem 0;

		.kt-pricing-2__title {
			text-align:center;
			margin-top: -3.57rem;
		}

		.btn-group {
			text-align:center;
			display: inline-block;
			margin: 2.5rem auto 0 auto;

			.btn {
			  	margin-right: -0.21rem;
				padding: 1rem 1.93rem 1rem 1.93rem;
				background: #fff;
				color: kt-base-color(label, 2);

				&.active {
					background: kt-brand-color();
					color: kt-brand-color(inverse);
				}
			}
		}
	}

	.kt-pricing-2__content {
		.kt-pricing-2__container {
			margin: 0 auto;
			max-width: 75%;
			margin-top: -21.64rem;

			.kt-pricing-2__items {
				background-color: #fff;
				position: relative;
				text-align: center;
				padding: 2.14rem 0.71rem 2.14rem 0.71rem;

				.kt-pricing-2__item {
					border-right: 1px solid #e1f1ff;
					width: 100%;

					&:last-child {
						border-right: none;
					}

					.kt-pricing-2__visual {
						position: relative;
						margin-top: 6.8rem;
						left: 50%;
    					transform: translateX(-50%) translateY(-50%);

						.kt-pricing-2__hexagon {
							height: 0;
							background: #F4FCFF;
							left: 50%;
    						transform: translateX(-50%) translateY(-50%);
							position: absolute;

							&:before {
								content: "";
								position: absolute;
								top: -2.14rem;
								width: 13.2rem;
								height: 0;
								border-left: 3.35rem solid transparent;
								border-right: 3.35rem solid transparent;
								border-bottom: 5.71rem solid #F4FCFF;
								left: 50%;
    							transform: translateX(-50%) translateY(-50%);
							}

							&:after {
								content: "";
								position: absolute;
								top: 3.57rem;
								width: 13.21rem;
								height: 0;
								border-left: 3.35rem solid transparent;
								border-right: 3.35rem solid transparent;
								border-top: 5.71rem solid #F4FCFF;
								left: 50%;
    							transform: translateX(-50%) translateY(-50%);
							}
						}

						.kt-pricing-2__icon {
							margin-top: 0.8rem;
							left: 50%;
    						transform: translateX(-50%) translateY(-50%);
							position: absolute;

							> i {
								font-size: 6.43rem;
							}
						}
					}

					.kt-pricing-2__subtitle {
						margin-top: 17.1rem;
						font-size: 1.64rem;
						color: kt-base-color(label, 3);
					}

					.kt-pricing-2__features {
						margin: 2.14rem 0 1.8rem;
						line-height: 2.2;

						> span {
						  	display: block;
						  	color: kt-base-color(label, 2);
						  	font-weight: 500;
						  }
					}

					.kt-pricing-2__price {
						font-size: 3.21rem;
						font-weight: 600;
						color: kt-base-color(label, 3);
					}

					.kt-pricing-2__label {
						margin: 0.64rem 0 0 0.35rem;
						font-size: 25px;
						position: absolute;
						color: kt-base-color(label, 2);
					}

					.kt-pricing-2__btn {
						margin: 2.14rem auto 2.14rem auto;

						> .btn {
							padding: 0.86rem 3.6rem 0.86rem 3.6rem;
							font-size: 0.86rem;
						}
					}
				}
			}
		}
	 }
}

@include kt-minimal-desktop() {
	.kt-pricing-2 {
		.kt-pricing-2__content {
			padding: 0 2.14rem 0 2.14rem;

			.kt-pricing-2__container {
				max-width: 90%;

				.kt-pricing-2__items {
					.kt-pricing-2__item {
				    	border-right: 1px solid #e1f1ff;
				    	padding-left: 0.71rem;
				    	padding-right: 0.71rem;

				    &:last-child {
						border-right: none;
					}

						.kt-pricing-2__btn {
							margin: 1.43rem auto 3.6rem auto;
						}
				    }
				}
			}
		}
	}
}

@include kt-tablet() {
	.kt-pricing-2 {
		.kt-pricing-2__content {
			padding: 0 2.14rem 0 2.14rem;

			.kt-pricing-2__container {
				max-width: 90%;

				.kt-pricing-2__items {

				    .kt-pricing-2__item {
				    	border-bottom: 1px solid #e1f1ff;
				    	border-right: none;
				    	padding-left: 0.71rem;
						padding-right: 0.71rem;
						margin-bottom: 2rem;

				    	&:last-child {
							border-bottom: none;
							margin-bottom: 0;
						}

						.kt-pricing-2__btn {
							margin: 1.43rem auto 3.6rem auto;
						}
				    }
				}
			}
		}
	}
}

@include kt-mobile() {
	.kt-pricing-2 {
		.kt-pricing-2__head {
			.kt-pricing-2__title {
				top: 4%;

				> h1 {
					font-size: 1.8rem;
				}
			}

			.btn-group {
			    top: 8%;

				.btn {
					padding: 0.71rem 0.71rem 0.71rem 0.71rem;
				}
			}
	    }

		.kt-pricing-2__content {
			.kt-pricing-2__container {
				width: 100%;

				.kt-pricing-2__items {
				    .kt-pricing-2__item {
				    	border-bottom: 1px solid #e1f1ff;
				    	border-right: none;
				    	padding-left: 0.71rem;
				    	padding-right: 0.71rem;
						margin-bottom: 2rem;

				    	&:last-child {
							border-bottom: none;
							margin-bottom: 0;
						}

						.kt-pricing-2__btn {
							margin: 1.43rem auto 3.6rem auto;
						}
				    }
				}
			}
		}
	}
}

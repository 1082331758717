$primary-color: #5867dd;
$month-height: 46px;

.flatpickr-calendar {
    .flatpickr-months {
        .flatpickr-month {
            background-color: #fff;
            height: $month-height + 8px;
        }
        .flatpickr-current-month {
            height: $month-height;
            display: flex;
            align-items: center;
            justify-content: center;
            .flatpickr-monthDropdown-months,
            input.cur-year {
                background-color: #fff;
                font-size: 14px;
            }
            .flatpickr-monthDropdown-months {
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                margin-right: 8px;
                .flatpickr-monthDropdown-month {
                    font-size: 1rem;
                    color: #5e6278;
                    font-weight: 500;
                    background-color: transparent;
                    outline: 0;
                    padding: 0;
                }
            }
        }
    }
    .flatpickr-weekdays {
        background-color: #fff;
        span.flatpickr-weekday {
            background-color: #fff;
        }
    }
    .flatpickr-day {
        border-radius: 0.475rem;
        &:hover,
        &.inRange {
            background-color: rgba($primary-color, 0.1);
            color: $primary-color;
            border-color: transparent;
            box-shadow: none;
        }
        &.today:not(.selected) {
            &,
            &:hover {
                color: #7e8299;
                background-color: #f5f8fa;
                border-color: transparent;
            }
        }
        &.selected,
        &.startRange,
        &.endRange {
            &,
            &:focus,
            &:hover,
            &.inRange,
            &.prevMonthDay,
            &.nextMonthDay {
                background: $primary-color;
                border-color: $primary-color;
                font-weight: normal;
            }
            &.startRange + .endRange:not(:nth-child(7n + 1)) {
                box-shadow: none;
            }
        }
    }
}

//
// Support Center/Home 2
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

// Base
.kt-sc-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;

	.kt-sc__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		z-index: 0;
		padding-right: 70px;
		overflow: hidden;

		.kt-svg-icon {
			height: 110%;
			width: auto;
			-webkit-transform: translateY(50px);
			transform: translateY(50px);
		}

		&--layer{
			top:0;
			left:0;
			right: 0;
			bottom: 0;
			padding-right: 0;

			.kt-svg-icon{
				height: auto;
				width: 100%;
				fill: rgba(103, 112, 153, 0.04);
				-webkit-transform: translateY(0);
				transform: translateY(0);

			}
		}
	}

	.kt-sc-bg-2{

		.kt-svg-icon{
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			height: auto;

			z-index:1;
		}

	}

	.kt-sc__top {
		min-height: 125px;
		margin-top: 2rem;
		position: relative;
		z-index: 1;

		.kt-sc__content {
			margin-right: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 3rem;

			.kt-sc__title{
				color: kt-base-color(label, 4);
				font-size: 2rem;
				font-weight: 600;
				margin-bottom: 2rem;
			}

			.kt-sc__form {

				.input-group{
					width: 450px;
					box-shadow: 0px 0px 27px 0px rgba(160,160,191,0.2);

					.input-group-text{
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						border: none;
						padding: 0.65rem 2rem;
						background-color: kt-state-color(brand, inverse);
					}

					.form-control{
						border: none;
						padding: 2.5rem 0;
						font-weight: 400;
						font-size: 1.1rem;
					}
				}
			}
		}
	}

	.kt-sc__bottom{
		padding: 25px 25px 0;
		position: relative;
		z-index: 1;

		.nav-tabs{
			margin-bottom: -25px;

			&.nav-tabs-line{
				border-bottom: 0;
				padding: 0 3rem;
			}

			.nav-item{
				margin-right: 50px;

				.nav-link, a.nav-link{
					font-size: 1.2rem;
					padding: 0 0 1.5rem;
					font-weight: 500;
					color: kt-base-color(label, 4);
					margin-bottom: 25px;
					border-bottom: 2px solid transparent;

					&:hover{
						border-bottom: 2px solid kt-state-color(brand, base);
						color: kt-base-color(label, 4);
					}

					&.active{
						border-bottom: 2px solid kt-state-color(brand, base);
						color: kt-state-color(brand, base);
					}
				}
			}
		}
	}

	&--wave{
		@include wave(important);

		&:after{
			bottom:25%;
			transform: rotate(45deg);
		}
	}
}

@include kt-tablet-and-mobile(){

	.kt-sc-2{
		min-height: auto;

		.kt-sc__bg{
			opacity: 0.2;
		}

		.kt-sc__top{
			min-height: auto;

			.kt-sc__content{
				padding: 4rem 0;
				margin-right: 0;
				min-height: auto;

				.kt-sc__form {

					.input-group{
						width: 100%;
					}
				}
			}
		}
	}
}

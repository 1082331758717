//
// Forms
//


// Include Form Components
@import "forms/base";

.input-group-append {
    .input-group-text {
        background-color: transparent;
    }
}
@import "../../config";

@include  kt-desktop() {
	.kt-login.kt-login--v1 .kt-login__aside.get-started-aside {
        width: 14rem ;
    }
    .get-started-steps {
        flex-direction: column;
        .step {
            margin: 1rem;
        }
    }
    .get-started-wrapper .kt-pricing-2{
        margin: -3rem -3rem;
    }
}

@include kt-tablet-and-mobile {
	.get-started-steps {
        flex-direction: row;
        justify-content: center;
        .step{
            margin: 1rem;
        }
        
    }
    .get-started-wrapper .kt-pricing-2{
        margin: -3rem -1.5rem;
    }
   
}


